import { Progress } from "antd";
import styled from "styled-components";

export const StyledCardDash = styled.div`
  border-radius: 1rem !important;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  background: ${(props) =>
    props.backgroundcolor || "#fff"}; // Use the background color from props
  padding: 30px 20px;
  height: 100%;
  position: relative;

  & h2 {
    /* color: #545454; */
    color: #fff;
    font-size: 24px !important;
    padding-top: 10px;
  }

  & h1 {
    font-size: 18px !important;
    /* color: #52c41a !important; */
    color: #fff !important;
  }

  & svg {
    font-size: 25px;
    /* color: #ff3594; */
    color: #fff;
  }

  & p {
    font-size: 10px !important;
  }
  div {
    position: absolute;
    opacity: 0.5;
    right: 0;
    bottom: 0;
  }
`;
export const StyledSaleCardRight = styled.div`
  margin-top: 30px;
  height: 400px;
  width: 100%;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;

  table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th{
  color: #000;
}

td, th {
  /* border: 1px solid #dddddd; */
  text-align: left;
}

/* tr:nth-child(even) {
  background-color: #dddddd;
} */

table thead {
    position: sticky;
    top: -1px;
    background-color: #f0f0f0;
  }

  table thead th {
    z-index: 1;
  }

  table th, table td {
    padding: 15px;
  }
`;

export const StyledChart = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  div {
    width: auto !important;
  }

  canvas {
    width: 100% !important;
  }
`;

export const StyledSaleCardMale = styled.div`
  margin-top: 30px;
  height: 185px;
  width: 100%;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 10px;

  div {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
  }
`;

export const StyledPieChart = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  div {
    width: auto !important;
    height: auto !important;
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

export const ConicProgress = styled(Progress)`
  .ant-progress-inner {
    transition: stroke-dasharray 0.5s, stroke 0.5s;
  }
`;
