import React from 'react'
import styled from 'styled-components'
import { Form } from 'antd'
import Flex from '../../../components/Flex'
import { Col } from 'antd'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
import BckImg1 from '../../../Images/bglogin1.png'


// ---------- LoginDesign Style --------

const LoginDesign = styled.div`
height: 100vh;
/* text-align: center; */
background-color: #fff;
.left_imge{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  position: relative;
  z-index: 1;
  & img {
    width: 100%;
  }
}
& h1 {
  font-size: 30px;
  font-weight: 800;
  color: #000;
  margin: 80px 0 20px 0;
}
& h3 {
  font-size: 20px;
  color: #a7a2b4;
  margin-right: 15px;
}

.btn_md {
  margin: 40px 0;
  display : flex;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 500px){
    display : flow-root;
  }
}

@media screen and (max-width:770px) {
  .left_side {
    display: none;
  }
  }
/* @media screen and (min-width: 701px) and (max-width: 2000px) {
  .btn_sm {
    display: none;
  }
} */

`


const LoginButton = styled(ButtonStandard)`
background-color: #3f51b5;
color: white;
border-radius: 0px !important;
height: 45px;
width: 100%;
font-size: large;
text-align: center;
display: flex;
justify-content: center;
align-items: center;


`

const StyledLogin = styled.div`
  padding: 20px;
  .ant-btn {
    height: 32px !important;
  }
`;

const StyledRegisterMenu = styled.div`
  max-width:400px;
  /* margin-top: 30px !important; */
  margin: auto;

  

/*   
  .cOGUEI {
    margin: auto;
    padding: 0px !important;
  }

  .dDHQxW {
    background: #e3f1ff;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 5px 5px;
    margin: auto;
    max-width: 100%;
    padding: 0px !important;
    border-radius: 10px;
  } */
`;


const SignInForm = ({ handleSignIn }) => {




  const [form] = Form.useForm();



  const onFinish = (values) => {

    handleSignIn(values);
  };

  const onFinishFailed = () => {

  }


  


  return (

    <LoginDesign>
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <CustomRow style={{ backgroundColor: '#f8fcff' }}>
          <Col span={24} md={12} style={{ backgroundColor: '#f8fcff', height: '100vh' }} className='left_side'>
            <div className='left_imge'>
              <img src={BckImg1} />
            </div>
          </Col>
        


            <Col span={24} md={12} style={{ backgroundColor: '#fff', margin: 'auto', padding: '0 25px', height: '100vh' }}>
            <StyledRegisterMenu>
              <h1>Welcome to HRM</h1>
              <Flex aligncenter={'true'} >
              </Flex>
              <h1>Sign in</h1>
              <CustomRow space={[12, 12]} style={{ marginTop: '10px' }}>
                <Col span={24} md={18} lg={20}>
                  <CustomInput placeholder={'Email'} name={'email'} type={'email'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Email !',
                      }
                    ]}
                  />
                </Col>
                <Col span={24} md={18} lg={20} style={{ marginTop: '10px' }}>
                  <CustomInputPassword placeholder={'Password'} type={'password'} name={'password'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Password !',
                      }
                    ]} />

                </Col>
                <Col span={24} md={18} lg={20} style={{ margin: '30px 0' }}>

                  <LoginButton text={'Login'} htmlType={'submit'} >Login</LoginButton>

                </Col>

              </CustomRow>
          </StyledRegisterMenu>

            </Col>
        </CustomRow>
      </Form>
    </LoginDesign>

  )
}

export default SignInForm
