import React, { useEffect, useState } from "react";
import { CustomPageTitle } from "../../../components/CustomPageTitle";
import { CustomRow } from "../../../components/CustomRow";
import { Col } from "antd";
import {
  ConicProgress,
  StyledCardDash,
  StyledSaleCardMale,
  StyledSaleCardRight,
} from "../style";
import { BarGraph } from "./BarGraph";
import { LineGraph } from "./LineGraph";
import { PieChart } from "./PieChart";
import { FcBusinessman, FcBusinesswoman } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import {
  getAbsentTable,
  getDashboard,
  getgender,
  selectAllAbsentTable,
  selectAllDashboard,
  selectAllGender,
} from "../DashboardSlice";
import { SVGICONS } from "./DashboardData";
import { CustomStandardTable } from "../../../components/Form/CustomStandardTable";
import { AiOutlineInbox } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Flex from "../../../components/Flex";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  useEffect(() => {
    dispatch(getgender());
  }, []);

  useEffect(() => {
    dispatch(getAbsentTable());
  }, []);

  const AllDashBoard = useSelector(selectAllDashboard);
  const AllGender = useSelector(selectAllGender);
  const AllAbsentData = useSelector(selectAllAbsentTable);

  useEffect(() => {
    setDataSource(AllAbsentData);
  }, [AllAbsentData]);

  const Male = AllGender?.map((item) => item?.male_percentage);
  const FeMale = AllGender?.map((item) => item?.female_percentage);

  const MoveToAttendance = () => {
    navigate('/attendance_report')
  }

  const employeedata = [
    {
      key: "1",
      rate: AllDashBoard?.employee ? AllDashBoard?.employee : 0,
      icon: SVGICONS.EMPLOYEETOTAL,
      p: "Total Employee",
      backgroundColor:
        "linear-gradient(135deg, rgb(35, 189, 184) 0, rgb(101, 169, 134) 100%)",
    },
    {
      key: "2",
      rate: AllDashBoard?.present_count ? AllDashBoard?.present_count : 0,
      icon: SVGICONS.PRESENTSVG,
      p: "Current Present",
      backgroundColor: "linear-gradient(135deg,#289cf5,#4f8bb7)",
    },
    {
      key: "3",
      rate: AllDashBoard?.absent_count ? AllDashBoard?.absent_count : 0,
      icon: SVGICONS.ABSENTSVG,
      p: "Current Absent",
      backgroundColor: "linear-gradient(135deg,#8e4cf1 0,#c554bc 100%)",
    },
    {
      key: "4",
      rate: AllDashBoard?.trainee ? AllDashBoard?.trainee : 0,
      icon: SVGICONS.TRAINEESVG,
      p: "Total Trainee",
      backgroundColor: "linear-gradient(135deg,#f48665 0,#d68e41 100%)",
    },
  ];

  const TableColumn = [
    {
      title: "SI No",
      render: (value, item, index) => index + 1,
    },
    {
      title: "User Id",
      dataIndex: "user_id",
    },
    {
      title: "Name",
      dataIndex: "user_name",
    },
    {
      title: "Department",
      dataIndex: "department_name",
    },
    {
      title: "Leave Days",
      dataIndex: "absent_count",
    },
  ];

  const data = [
    {
      user_name: "sdfad",
      user_id: 66666,
      department: "sdfad",
      leave_count: 6,
    },
  ];

  const conicColors = {
    "0%": "#87d068",
    "50%": "#ffe58f",
    "100%": "#ffccc7",
  };

  return (
    <div>
      <CustomPageTitle Heading={"DASHBOARD"} />
      <CustomRow space={[24, 24]}>
        {employeedata.map((item, i) => (
          <Col span={24} md={12} key={i} lg={6} sm={12}>
            <StyledCardDash backgroundcolor={item?.backgroundColor}>
              <h1>{item?.p} </h1>
              <Flex>
                <img src={item?.icon} alt="" />
              </Flex>
              <h2>{item?.rate}</h2>
            </StyledCardDash>
          </Col>
        ))}
      </CustomRow>
      <CustomRow space={[24, 24]}>
        <Col span={24} md={9}>
          <StyledSaleCardRight>
            <BarGraph />
          </StyledSaleCardRight>
        </Col>
        <Col span={24} md={9}>
          <StyledSaleCardRight>
            <LineGraph />
          </StyledSaleCardRight>
        </Col>
        <Col span={24} md={6}>
          <CustomRow>
            <Col span={24} md={24}>
              <StyledSaleCardMale>
                <p style={{ fontSize: "18px", color: "#000", padding: "12px" }}>
                  Male
                </p>
                <div>
                  <FcBusinessman size={70} />
                  <ConicProgress
                    type="circle"
                    percent={Male}
                    strokeColor={conicColors}
                  />
                </div>
              </StyledSaleCardMale>
            </Col>
          </CustomRow>
          <CustomRow>
            <Col span={24} md={24}>
              <StyledSaleCardMale>
                <p style={{ fontSize: "18px", color: "#000", padding: "12px" }}>
                  Female
                </p>
                <div>
                  <FcBusinesswoman size={70} />
                  <ConicProgress
                    type="circle"
                    percent={FeMale}
                    strokeColor={conicColors}
                  />
                </div>
              </StyledSaleCardMale>
            </Col>
          </CustomRow>
        </Col>
        <Col span={24} md={12}>
          <StyledSaleCardRight>
            <PieChart />
          </StyledSaleCardRight>
        </Col>
        <Col span={24} md={12}>
          <StyledSaleCardRight onClick={MoveToAttendance}>
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>User Id</th>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Leave Days</th>
                </tr>
              </thead>
              <tbody>
                {AllAbsentData && AllAbsentData.length > 0 ? (
                  AllAbsentData.map((item, index) =>
                    item?.absent_count != null && item?.absent_count > 0 ? (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.user_id}</td>
                        <td>{item?.user_name}</td>
                        <td>{item?.department_name}</td>
                        <td>{item?.absent_count}</td>
                      </tr>
                    ) : null
                  )
                ) : (
                  <tr>
                    <td colSpan="5" style={{ fontSize: "15px", textAlign: "center", paddingTop: "30px" }}>
                      {/* <AiOutlineInbox/> */}
                      &nbsp;No Data
                    </td>
                  </tr>
                )}

              </tbody>
            </table>
          </StyledSaleCardRight>
        </Col>
      </CustomRow>
    </div>
  );
};

export default Dashboard;
