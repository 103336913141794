import React from 'react'
import { CustomCardView } from '../../../components/CustomCardView'

const PromotionDescriptionModal = ({descriptionrecord}) => {
  return (
    <CustomCardView>
        <h1 style={{color : '#0d6efd'}}>{descriptionrecord?.description}</h1>
    </CustomCardView>
  )
}

export default PromotionDescriptionModal