import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";
import { APIURLS } from "../../utils/ApiUrls";


const initialState = {
  userdetails: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// get Holiday

export const getUserDetails = createAsyncThunk(
  "userDetails/get",
  async () => {
    try {
      const User = 'User';
      const response = await request.get(`${APIURLS.GETUSER}`, {
        params: { User }
      })
      return response.data
    } catch (error) {
      // throw error;
    }
  });

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder

      // User 

      .addCase(getUserDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userdetails = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

// User

export const selectAllUser = (state) => state.userdetails.userdetails;
export const getUserStatus = (state) => state.userdetails.status;
export const getUserError = (state) => state.userdetails.error;

export const { reducer } = userDetailsSlice;
export const { addDepartment } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
