//bank detail api
const PUTBANKDETAILS = 'bank/edit/employee/';
const BANKDETAILS = 'bank';

//personal details api
const PUTPERSONALDETAILS = 'personal/edit/';
const PERSONALDETAILS = 'personal';

//Emergency Contact
const PUTEMERGENCYDETAIL = 'emergencycontacts/edit/';
const EMERGENCYDETAIL = 'emergencycontacts';

//Family Details
const PUTFAMILYDETAIL = 'family/edit/';
const FAMILYDETAIL = 'family';

//Departments
const POSTDEPARTMENT = 'department/save';
const PUTDEPARTMENT = 'department/edit/';
const GETDEPARTMENT = 'department';

//Designation
const POSTDESIGNATION = 'designation/save';
const PUTDESIGNATION = 'designation/edit/';
const GETDESIGNATION = 'designation';

//Initial Employee Details
const POSTINITIALEMPLOYEE = 'employees/save';
const PUTINITIALEMPLOYEE = 'employees/edit/';
const GETINITIALEMPLOYEE = 'employees/view'
const GETINITIALEMPLOYEETRUE = 'employees/true'

//Holiday 
const POSTHOLIDAY = 'leavetype/save';
const PUTHOLIDAY = 'leavetype/edit/';
const GETHOLIDAY = 'leavetype';

//Promotions
const POSTPROMOTIONS = 'promotions/save';
const PUTPROMOTIONS = 'promotions/edit/';
const GETPROMOTIONS = 'promotions/view';

//Employee Qualification
const PUTQUALIFICATIONDETAIL = 'qualification/update/'
const QUALIFICATIONDETAIL = 'qualification'


//Employee Complaints
const POSTCOMPLAINTS = 'complaints/save';
const GETCOMPLAINTS = 'complaints/view';
const PUTCOMPLAINTS = 'complaints/edit/';

// DepartmentRole
const GETDEPARTMENTROLE = 'department/role'
const GETDEPARTMENTEMP = 'department/emp/salary'

//Resignations
const RESIGNATIONS = 'resignations'
const RESIGNATIONVIEW = 'resignations/view'
const RESIGNATIONEDIT = 'resignations/edit/'

//Shifts
const POSTSHIFT = 'shift/save'
const GETSHIFT = 'shift'
const PUTSHIFT = 'shift/edit/'


//Dashboard
const GETEMPLOYEECOUNTS = 'employees/year/count'
const GETDASHDEPARTMENTS = 'employees/department'
const GETDASHBOARDDETAILS = 'dashboard'
const GETGENDERS = 'employees/other'
const GETATTENDANCEPER = 'attendance1/percentage'
const GETABSENTTABLES = 'attendance1/absent/count'

//  Attendance
const GETATTENDANCE = 'employees/shift'
const EDITGETATTENDANCE = 'employees/attendance'
const PUTATTENDANCE = 'attendance/date/edit'

// Business Profile
const POSTBUSINESSPROFILE = 'company/save'
const GETBUSINESSPROFILE = 'company'
const PUTBUSINESSPROFILE = 'company/edit/'

//Notification
const GETNOTIFICATIONS = 'notifications'

// Basic Salary 
const POSTBASICSALARY = 'salary/save'
const GETBASICSALARY = 'salary/type/view'
const PUTBASICSALARY = 'department/employee/salary/'
const GETWITHOUTBASICSALARYEMP = 'department/role/filter'

//Training Details
const GETTRAININGS = 'traineeDetails'
const POSTTRAININGS = 'traineeDetailss/save'
const PUTTRAININGS = 'traineeDetails/edit/'

// Report(Payroll)
const GETPAYROLLREPORTS = 'department/emp/payroll'

// Payroll
const POSTPAYROLL = 'payroll/save'
const GETPAYROLL = 'monthly/payroll/view'
const PUTPAYROLL = 'payroll/type/edit/'

// Admin Account 
const GETADMINACCOUNT = 'admin'
const PUTADMINACCOUNT = 'admin/edit/'

// Trainee Status
const PUTTRAINEESTATUS = 'traineeDetails/ststus/'

// Attendance Report 
const GETATTENDANCEREPORT = 'attendance1/'

// User
const POSTUSER = 'user/save'
const GETUSER = 'User'
const PUTUSER = 'user/edit/'
const STATUSUSER = 'user/ststus/'
const GETINDIVIDUALUSER = 'user'
const GETLOGINDETAILS = 'admin/view'

export const APIURLS = {
    //bank detail api
    PUTBANKDETAIL: PUTBANKDETAILS,
    GETBANKDETAIL: BANKDETAILS,

    //personal details api
    PUTPERSONALDETAIL: PUTPERSONALDETAILS,
    GETPERSONALDETAILS: PERSONALDETAILS,

    //Emergency Contact
    PUTEMERGENCYDETAILS: PUTEMERGENCYDETAIL,
    GETEMERGENCYDETAILS: EMERGENCYDETAIL,

    //Family Details
    PUTFAMILYDETAILS: PUTFAMILYDETAIL,
    GETFAMILYDETAILS: FAMILYDETAIL,


    //Department
    POSTDEPARTMENT: POSTDEPARTMENT,
    PUTDEPARTMENT: PUTDEPARTMENT,
    GETDEPARTMENT: GETDEPARTMENT,

    //Designation
    POSTDESIGNATION: POSTDESIGNATION,
    PUTDESIGNATION: PUTDESIGNATION,
    GETDESIGNATION: GETDESIGNATION,

    //Initial Employee Details
    POSTINITIALEMPLOYEE: POSTINITIALEMPLOYEE,
    PUTINITIALEMPLOYEE: PUTINITIALEMPLOYEE,
    GETINITIALEMPLOYEE: GETINITIALEMPLOYEE,
    GETINITIALEMPLOYEETRUE: GETINITIALEMPLOYEETRUE,

    //Holiday 
    POSTHOLIDAY: POSTHOLIDAY,
    PUTHOLIDAY: PUTHOLIDAY,
    GETHOLIDAY: GETHOLIDAY,

    //Promotions
    POSTPROMOTIONS: POSTPROMOTIONS,
    PUTPROMOTIONS: PUTPROMOTIONS,
    GETPROMOTIONS: GETPROMOTIONS,

    //Employee Qualification
    PUTQUALIFICATION: PUTQUALIFICATIONDETAIL,
    GETQUALIFICATION: QUALIFICATIONDETAIL,


    //Employee Complaints
    POSTCOMPLAINT: POSTCOMPLAINTS,
    GETCOMPLAINT: GETCOMPLAINTS,
    PUTCOMPLAINT: PUTCOMPLAINTS,

    //Department Role

    GETDEPARTMENTROLE: GETDEPARTMENTROLE,
    GETDEPARTMENTEMP: GETDEPARTMENTEMP,


    //RESIGNATIONS

    RESIGNATIONS: RESIGNATIONS,
    RESIGNATIONVIEW: RESIGNATIONVIEW,
    RESIGNATIONEDIT: RESIGNATIONEDIT,

    //Shift

    POSTSHIFT: POSTSHIFT,
    GETSHIFT: GETSHIFT,
    PUTSHIFT: PUTSHIFT,

    //Dashboard
    GETEMPLOYEECOUNT: GETEMPLOYEECOUNTS,
    GETDASHDEPARTMENT: GETDASHDEPARTMENTS,
    GETDASHBOARDDETAIL: GETDASHBOARDDETAILS,
    GETGENDERDETAIL: GETGENDERS,
    GETATTENDANCEPERCENTAGE: GETATTENDANCEPER,
    GETABSENTTABLE: GETABSENTTABLES,

    POSTSHIFT: POSTSHIFT,
    GETSHIFT: GETSHIFT,
    PUTSHIFT: PUTSHIFT,

    //  Attendance
    GETATT: GETATTENDANCE, // -----> Get Attendance Search
    EDITGETATT: EDITGETATTENDANCE, // -----> Get Edit Attendance Search
    PUTATT: PUTATTENDANCE, // -----> Put Attendance 

    // Business Profile
    POSTBUSINESSPROFILE: POSTBUSINESSPROFILE,
    GETBUSINESSPROFILE: GETBUSINESSPROFILE,
    PUTBUSINESSPROFILE: PUTBUSINESSPROFILE,

    //NOTIFICATION

    GETNOTIFICATION: GETNOTIFICATIONS,

    //Training
    POSTTRAINING: POSTTRAININGS,
    GETTRAINING: GETTRAININGS,
    PUTTRAINING: PUTTRAININGS,

    // Reports ( Payroll )
    GETPAYROLLREPORTS: GETPAYROLLREPORTS,

    // Admin Account Get
    GETADMINACCOUNT: GETADMINACCOUNT,
    PUTADMINACCOUNT: PUTADMINACCOUNT,

    // Trainee Status
    PUTTRAINEESTATUS: PUTTRAINEESTATUS,

    // Attendance Report
    GETATTENDANCEREPORT: GETATTENDANCEREPORT,

    // User
    POSTUSER: POSTUSER,
    GETUSER: GETUSER,
    PUTUSER: PUTUSER,
    STATUSUSER: STATUSUSER,
    GETINDIVIDUALUSER: GETINDIVIDUALUSER,           //  Not used
    GETLOGINDETAILS : GETLOGINDETAILS,

    // Basic Salary 
    POSTBASICSALARY : POSTBASICSALARY,
    GETBASICSALARY : GETBASICSALARY,
    PUTBASICSALARY : PUTBASICSALARY,
    GETWITHOUTBASICSALARYEMP : GETWITHOUTBASICSALARYEMP,

    // Payroll
    GETPAYROLL : GETPAYROLL,
    PUTPAYROLL  : PUTPAYROLL,
    POSTPAYROLL : POSTPAYROLL
}

