import React, { useEffect, useState } from 'react'
import { Col, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import request from '../../../utils/request'
import { CustomInput } from '../../../components/Form/CustomInput'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import { CustomRow } from '../../../components/CustomRow'
import { CustomDatePicker } from '../../../components/Form/CustomDatePicker'
import dayjs from 'dayjs'
import { getHoliday } from '../HolidaySlice'
import { APIURLS } from '../../../utils/ApiUrls'
import { getNotification } from '../../Notifications/Partials/NotificationSlice'


export const HolidayForm = ({ FormExternalClose, FormExternalClosee, updatetrigger, holidayrecord }) => {

    // ----- Define Form
    const [form] = useForm()
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));

    useEffect(() => {
        form.resetFields();
      }, [form, updatetrigger])

    const dispatch = useDispatch();

    useEffect(() => {
        if (holidayrecord) {
            setHoliday()
        }
    }, [holidayrecord, updatetrigger])

    const setHoliday = () => {

        const startDate = new Date(holidayrecord?.date);
        const dateFormat = 'YYYY/MM/DD';
        const starttDate = dayjs(startDate).format(dateFormat);

        form.setFieldsValue(holidayrecord)
        form.setFieldsValue({ date: dayjs(starttDate, dateFormat), })
    }

    const UpdateHoliday = (values) => {
        request.put(`${APIURLS.PUTHOLIDAY}${holidayrecord?.holidaysId}`, values)
            .then(function (response) {
                toast.info('Holiday Details Updated Successfully')
                dispatch(getHoliday());
                dispatch(getNotification());
                FormExternalClosee()
            })
            .catch(error => { })
    }

    const handleDate = (date) => {
        setSelectedDate(date);
        const dayOfWeek = dayjs(date).format('dddd');
        form.setFieldsValue({ day: dayOfWeek });
    }

    const AddHolidayPost = (value) => {
        request.post(`${APIURLS.POSTHOLIDAY}`, value)
            .then(function (response) {
                dispatch(getHoliday());
                dispatch(getNotification());
                FormExternalClose();
                form.resetFields();
                toast.success('Holiday Added Successfully')
            })
            .catch(error => { })
    }

    const onFinish = (values) => {


        if (holidayrecord) {
            const NewValues = { ...values, date: selectedDate}
            UpdateHoliday(NewValues)
        }
        else {
            AddHolidayPost(values)
        }
    }

    const onFinishFailed = (value) => {
    }

    const onReset = () => {
        form.resetFields();
    }

    return (
        <Form
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div style={{ margin: '30px 0px' }}>
                <CustomRow space={[12, 12]}>

                    <Col span={24} md={12}>
                        <CustomDatePicker label={'Date'} placeholder={'Enter Date'} onChange={handleDate}
                            name={'date'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Date ! ! !',
                                },

                            ]} />
                    </Col>


                    <Col span={24} md={12}>
                        <CustomInput label={'day'} placeholder={'DAY'}
                            name={'day'} disabled/>
                    </Col>

                    <Col span={24} md={12}>
                        <CustomInput label={'Title'} placeholder={'Enter Title'}
                            name={'title'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Title ! ! !',
                                },

                            ]} />
                    </Col>

                </CustomRow>

            </div>
            <Flex gap={'20px'} center={"true"} margin={'20px 0'}>
                {
                    holidayrecord ? (
                        <>
                            <Button.Primary text={'Update'} htmlType={'submit'} />
                            <Button.Danger text={'Cancel'} onClick={() => FormExternalClosee()} /></>
                    ) : (
                        <>
                            <Button.Success text={'Submit'} htmlType={'submit'} />
                            <Button.Danger text={'Reset'} onClick={() => onReset()} />
                        </>
                    )
                }
            </Flex>

        </Form>
    )
}
