import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { Col } from 'antd'
import { CustomCardView } from '../../../components/CustomCardView'
import { GrEdit } from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux'
import request, { base } from '../../../utils/request'
import { CustomModal } from '../../../components/CustomModal'
import { AdminAccountForm } from './AdminAccountForm'
import { Details, EmpDetails } from '../../EmployeeDetails/Style'
import { getAdminAccount, selectAllAdminAccount } from '../AdminAccountSlice'
import Flex from '../../../components/Flex'
import { selectCurrentRoleID, selectCurrentUserId } from '../../Auth/authSlice'
import { APIURLS } from '../../../utils/ApiUrls'

export const ViewAdminAccount = () => {

    const dispatch = useDispatch()
    const [adminAcc, setAdminAccount] = useState([])

    // useEffect(() => {
    //     dispatch(getAdminAccount())
    // }, [])

    // const adminAcc = useSelector(selectAllAdminAccount)

    const userID = useSelector(selectCurrentUserId)
    const RoleID = useSelector(selectCurrentRoleID)

    useEffect(() => {
        GetUserDetaisss();
    }, []);

    const GetUserDetaisss = () => {

        request
            .get(`${APIURLS.GETLOGINDETAILS}/${userID}/${RoleID}`)
            .then(function (response) {
                setAdminAccount(response.data);
            })
            .catch(function (error) {
            });
    };

    // useEffect(() => {
    //     setAdminAccount(adminAcc?.AdminLogin_1)
    // }, [setAdminAccount])

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalContent, setModalContent] = useState(null)
    const [formReset, setFormReset] = useState(0);
    const [trigger, setTrigger] = useState(0)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false);
    }

    const FormRest = () => {
        setFormReset(formReset + 1);
    };

    const FormExternalClose = () => {
        handleOk();
        FormRest()
    }

    const AdminAccountView = () => {
        setTrigger(trigger + 1)
        setModalTitle('Admin Account');
        setModalContent(<AdminAccountForm FormExternalClosee={FormExternalClose} formname={'AddAdminAccount'} adminAcc={adminAcc} updatetrigger={trigger} GetUserDetaisss={GetUserDetaisss}/>);
        showModal()
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        FormRest()
    }

    return (
        <CustomRow>
            <Col span={24} md={24}>
                <CustomCardView>
                    <CustomRow space={[12, 12]}>
                        <Col span={24} md={10}>
                            <EmpDetails>
                                <img
                                    src={`${base}${adminAcc?.image}`}
                                    alt="img"
                                    width={100}
                                    height={100}
                                    style={{ borderRadius: "25%", objectFit: "cover" }}
                                />
                                <div style={{ marginLeft: "20px" }}>
                                    <p
                                        style={{
                                            fontWeight: "900",
                                            fontSize: "25px",
                                            color: "#000",
                                        }}
                                    >
                                        {/* {detailget?.userName} */}
                                    </p>
                                    {/* <p>{detailget?.departmentName}</p> */}
                                    <br />
                                    <p></p>
                                    <br />
                                    <h1 style={{ color: "#000" }}>
                                        {adminAcc?.name}
                                    </h1>
                                    {/* <p>Date of Join : {detailget?.date}</p> */}
                                </div>
                            </EmpDetails>
                        </Col>
                        <Col span={24} md={14}>
                            <Flex flexEnd={'true'}>
                                <div className="icon-places">
                                    {/* <FiPlus className="Add-icon" /> */}
                                    <GrEdit
                                        className="icon"
                                        onClick={() => {
                                            AdminAccountView();
                                        }}
                                    />
                                </div>
                            </Flex>

                            <Details>
                                <CustomRow
                                    style={{ position: "relative" }}
                                    space={[12, 12]}
                                >
                                    <Col span={24} md={23} sm={23}>
                                        <CustomRow space={[12, 12]}>
                                            <Col span={24} md={11} sm={11}>
                                                <p className="para">Email :</p>
                                            </Col>
                                            <Col span={24} md={13} sm={13}>
                                                <p className="paraBlue">
                                                    {adminAcc?.email}
                                                </p>
                                            </Col>
                                            <Col span={24} md={11} sm={11}>
                                                <p className="para">Password :</p>
                                            </Col>
                                            <Col span={24} md={13} sm={13}>
                                                <p className="paraBlue">
                                                    *******
                                                </p>
                                            </Col>
                                            <Col span={24} md={11} sm={11}>
                                                <p className="para">Confirm Password:</p>
                                            </Col>
                                            <Col span={24} md={13} sm={13}>
                                                <p className="paraBlue">
                                                    *******
                                                </p>
                                            </Col>

                                        </CustomRow>
                                    </Col>

                                </CustomRow>
                            </Details>
                        </Col>
                    </CustomRow>
                </CustomCardView>
            </Col>

            <CustomModal isVisible={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} modalTitle={modalTitle} modalContent={modalContent} />
        </CustomRow>

    )
}
