import React from 'react'
import AllReports from './AllReports/Partials/AllReports'

export const AllReportsIndex = () => {
  return (
    <div>
       < AllReports /> 
    </div>
  )
}
