import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TrainingForm } from "./TrainingForm";
import { Col, Flex, Popconfirm } from "antd";
import { FiEdit, FiPlus } from 'react-icons/fi'
import { CustomPageFormTitle, CustomPageTitle } from "../../../components/CustomPageTitle";
import { CustomRow } from "../../../components/CustomRow";
import CustomInputSearch from "../../../components/Form/CustomInputSearch";
import { CustomStandardTable } from "../../../components/Form/CustomStandardTable";
import { CustomModal } from "../../../components/CustomModal";
import Button from "../../../components/Form/CustomButton";
import { getTraining, getTrainingError, getTrainingStatus, selectAllTraining } from "../TrainingSlice";
import { CommonLoading } from "../../../components/CommonLoading";
import { CustomTag } from "../../../components/Form/CustomTag";
import { TableIconHolder } from "../../../components/CommonStyled";
import { THEME } from "../../../theme";
import { FaExchangeAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { APIURLS } from "../../../utils/ApiUrls";
import request from "../../../utils/request";
import { CustomLableBack } from "../../../components/CustomLableBack";
import { useNavigate } from "react-router-dom";


const ViewTraining = () => {

    const [dataSource, setDataSource] = useState([])
    const [searchTexts, setSearchTexts] = useState([]);
    const [formReset, setFormReset] = useState(0);
    const [trigger, setTrigger] = useState(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormRest()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        FormRest()
    };

    const FormRest = () => {
        setFormReset(formReset + 1);
    };

    const FormExternalClose = () => {
        handleOk();

    };

    const ToEmployee =()=>{
        navigate('/viewprofile')
    }

    useEffect(() => {
        dispatch(getTraining())
    }, [])

    const AllTrainingDetails = useSelector(selectAllTraining)
    const AllTrainingStatus = useSelector(getTrainingStatus)
    const AllTrainingError = useSelector(getTrainingError)

    const UpdateTraining = (record) => {
        setTrigger(trigger + 1)
        setModalTitle('Update Training');
        setModalContent(<TrainingForm FormExternalClosee={FormExternalClose} formname={'training'} formReset={formReset} trainingrecord={record} updatetrigger={trigger} />);
        showModal()
    }

    const TraineeStatus = (record) => {
        if (record.status === false || record.status === true) {
            request
                .put(`${APIURLS.PUTTRAINEESTATUS}${record.traineeId}`)
                .then(function (response) {
                    dispatch(getTraining());
                    if (response.data.status === true) {
                        toast.success('You Changed Into Staff');
                    }
                })
                .catch(function (error) {
                });
        } else {
            toast.warn('InActive');
        }
    }

    useEffect(() => {
        setDataSource(AllTrainingDetails)
    }, [AllTrainingDetails])

    const TableColumn = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: "Trainee Name",
            dataIndex: "traineeName",
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return (
                    String(record.traineeName)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.traineeName).includes(value.toUpperCase())
                );
            },
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
        },
        {
            title: "End Date",
            dataIndex: "endDate",
        },
        {
            title: "Email",
            dataIndex: "emailId",
        },
        {
            title: "Mobile Number",
            dataIndex: "mobileNumber",
        },
        {
            title: 'Status',

            render: (record, i) => {

                return (
                    <Fragment>
                        <Flex center={'true'}>
                            {record.status === true ? (
                                <CustomTag bordered={"true"} color={'processing'} title={'STAFF'} />
                            ) : (<CustomTag bordered={"true"} color={'error'} title={'TRAINEE'} />)
                            }
                        </Flex>
                    </Fragment>
                );
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        {
                            record.status === false ? (<>  <FiEdit onClick={() => UpdateTraining(record)} /></>) : null
                        }
                        <Popconfirm
                            title="Change The Status"
                            description="Do you want to change this Trainee to 'STAFF'?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => TraineeStatus(record)}
                        >
                            <TableIconHolder color={THEME.PRIMARY_PURPLE} size={'22px'}>
                                {
                                    record.status === false ? (<><FaExchangeAlt /></>) : (<><Button.Primary text={'+ Add To Staff'} onClick={ToEmployee} /></>)
                                }

                            </TableIconHolder>
                        </Popconfirm>
                    </Flex>
                );
            },
        },
    ]

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const AddTraing = () => {
        setModalTitle("Add Training");
        setTrigger(trigger + 1)
        setModalContent(
            <TrainingForm
                formname={"AddTrainingForm"}
                FormExternalClose={FormExternalClose}
                updatetrigger={trigger}
            />
        );
        showModal();
    };

    let content;

    if (AllTrainingStatus === 'loading') {
        content = <CommonLoading />
    } else if (AllTrainingStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.traineeId;
        content = <CustomStandardTable columns={TableColumn} data={dataSource} rowKey={rowKey} />
    } else if (AllTrainingStatus === 'failed') {
        content = <h2>
            {AllTrainingError}
        </h2>
    }

    return (
        <div>
            <Flex>
                <CustomLableBack />
                <CustomPageTitle Heading={"Training Details"} />
            </Flex>

            <CustomRow
                space={[24, 24]}
                style={{ background: "#dae1f3", paddingTop: "12px" }}
            >
                <Col span={24} md={10} style={{ display: "flex", gap: "10px", alignItems: 'baseline' }}>
                    <CustomPageFormTitle Heading={"Training"} />
                    <CustomInputSearch
                        placeholder={"search ..."}
                        value={searchTexts}
                        onChange={(e) => handleSearchs(e.target.value)}
                    />
                </Col>
                <Col span={24} md={14}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}></Col>
                        <Col span={24} md={8} style={{ float: "right" }}>
                            <Flex style={{ marginRight: "-30px", justifyContent: "end" }}>
                                <Button.Primary
                                    style={{ borderRadius: "6px" }}
                                    icon={<FiPlus style={{ fontSize: "20px" }} />}
                                    text={"Add"}
                                    onClick={AddTraing}
                                />
                            </Flex>
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
            {content}
            <CustomModal
                isVisible={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                width={800}
                modalTitle={modalTitle}
                modalContent={modalContent}
            />
        </div>
    )
}

export default ViewTraining