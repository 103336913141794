import { Col, Form } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { CustomRow } from '../../../../components/CustomRow'
import { CustomSelect } from '../../../../components/Form/CustomSelect'
import { CustomPageTitle } from '../../../../components/CustomPageTitle'
import dayjs from "dayjs";
import { getDesignation, getInitialEmployee, selectAllDesignation, selectAllInitialEmployee } from '../../../EmployeeDetails/EmployeeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomDatePicker } from '../../../../components/Form/CustomDatePicker'
import { CustomTextArea } from '../../../../components/Form/CustomTextArea'
import Flex from '../../../../components/Flex'
import Button from '../../../../components/Form/CustomButton'
import request from '../../../../utils/request'
import { toast } from 'react-toastify'
import { APIURLS } from '../../../../utils/ApiUrls'
import { getEmployeesTrue, selectAllEmployeeTrue } from './ExitSlice'
import { getResignation } from '../../Resignation/Partials/ResignationSlice'

const EmployeeExit = ({ trigger, FormExternalClose, updateRecord }) => {

  useEffect(() => {
    form.resetFields();
  }, [trigger])

  const ADD_EMP_RESIGNATION_URL = 'resignations/save'

  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [fromDate, setSelectedfromDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [tooDate, setSelectedToDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [exitType, setExitType] = useState('Resignations');

  const [emp, setEmp] = useState([])

  const [selectEmp, setSelectedEmp] = useState([])


  useEffect(() => {
    if (updateRecord) {
      setEmpExit()
    }
  }, [updateRecord,trigger])


  const setEmpExit = () => {
    const ExitDate = new Date(updateRecord?.resignations_date);
    const dateFormat = 'YYYY/MM/DD';
    const exitttDate = dayjs(ExitDate).format(dateFormat);

    form.setFieldsValue(updateRecord)
    form.setFieldsValue({
      resignationsDate: dayjs(exitttDate, dateFormat),
    })
    form.setFieldsValue({ userName: updateRecord.user_name })
    form.setFieldsValue({ employeeId: updateRecord.employee_id })
  }


  const handleExitTypeChange = (value) => {
    setExitType(value);
  };

  const UpdateEmpExit = (values) => {
    request.put(`${APIURLS.RESIGNATIONEDIT}${updateRecord?.resignations_id}/`, values)
      .then(function (response) {
        toast.info("Employee Exit Updated Successfully")
        dispatch(getResignation())
        FormExternalClose()
      })
      .catch(function (error) { });
  }


  const onFinish = (values) => {
    const record = { ...values, resignationsDate: fromDate }
    let result = {
      employeeId: record.employeeId,
      resignationsDate: record.resignationsDate,
      reason: record.reason,
      type: record.type,
    }

    if (updateRecord) {
      UpdateEmpExit(result);
    }
    else {
      AddSalary(result);
    }


  }

  useEffect(() => {
    form.setFieldsValue({ role: emp?.departmentName })
    form.setFieldsValue({ employeeId: emp?.employeeId })
  }, [emp])


  const AddSalary = (values) => {
    request.post(`${ADD_EMP_RESIGNATION_URL}`, values)
      .then(function (response) {
        toast.success("Added Employeee Resignation")
        form.resetFields();
        FormExternalClose()
        dispatch(getResignation())
      })
      .catch(function (error) {
        toast.error(" Failed");
      });
  }


  const onFinishFailed = () => {

  }

  const onReset = () => {
    form.resetFields();
  };

  const handleDate = (e) => {
    setSelectedDate(e)
  }

  const handleFromDate = (e) => {
    setSelectedfromDate(e)
  }

  const handleToDate = (e) => {
    setSelectedToDate(e)
  }


  useEffect(() => {
    dispatch(getInitialEmployee())
    dispatch(getEmployeesTrue())
    dispatch(getDesignation())
  }, [])

  const ExitType = [
    {
      label: 'Resignation',
      value: 'Resignations'

    },
    {
      label: 'Termination',
      value: 'termination'
    },
  ];


  const SelectEmp = useSelector(selectAllEmployeeTrue)

  const initialEmp = useSelector(selectAllInitialEmployee)

  const EmpDesignation = useSelector(selectAllDesignation)

  const selectedEmpDetails = (value) => {
    const EmpDetails = SelectEmp.find((emp) => emp.userName === value);
    setEmp(EmpDetails)
  };

  const AllEmployee = SelectEmp?.map((empdetails) => ({
    label: `${empdetails.userName}`,
    value: `${empdetails.userName}`
  }));


  return (
    <Fragment>
      <CustomPageTitle Heading={"Exit"} />
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <CustomRow space={[24, 24]}>
          <Col span={24} md={12}>
            <CustomSelect label={'Type'} options={ExitType} onChange={handleExitTypeChange} name={'type'} />
          </Col>
        </CustomRow>
        <br></br>
        <CustomRow space={[24, 24]}>

          <Col span={24} md={12}>
            <CustomSelect label={'Name'} name={'userName'} options={AllEmployee} onChange={selectedEmpDetails} />
            <CustomInput name={'employeeId'} display={'none'}  />
          </Col>
          {/* <Col span={24} md={12}>
            <CustomInput label={'Role'} name={'role'} disabled />
          </Col> */}
          <Col span={24} md={12}>
            <CustomDatePicker label={'Date'} name={'resignationsDate'} onChange={handleFromDate} />
          </Col>

          {/* {exitType !== 'termination' && (
            <>
              <Col span={24} md={12}>
                <CustomDatePicker label={'Notice From Date'} name={'noticeDate'} onChange={handleFromDate} />
              </Col>
              <Col span={24} md={12}>
                <CustomDatePicker label={'Notice To Date'} name={'toDate'} onChange={handleToDate} />
              </Col>
            </>
          )} */}
          <Col span={24} md={24}>
            <CustomTextArea label={'Reason'} name={'reason'} />
          </Col>
        </CustomRow>
        <Flex gap={'20px'} center={"true"} margin={'20px 0'}>
          {updateRecord ? (
            <>
              <Button.Primary text={'Update'} htmlType={'submit'} />
              <Button.Danger text={'Cancel'} onClick={() => FormExternalClose()} />
            </>
          ) : (
            <>
              <Button.Success text={'Submit'} htmlType={'submit'} />
              <Button.Danger text={'Reset'} onClick={() => onReset()} />
            </>
          )}
        </Flex>

      </Form>
    </Fragment>
  )
}

export default EmployeeExit