import React from 'react'
import ViewUserTable from './Partials/ViewUserTable'

export const AddUserMain = () => {
    return (
        <div>
            <ViewUserTable />
            {/* <CustomCardView>
                <AddUser />
            </CustomCardView> */}
        </div>
    )
}
