import UserSignin from "../../modules/Auth/Components/UserSignin";
import { ViewEmploye } from "../../modules/EmployeeDetails/ViewEmployee";
import ViewEmployeeProfile from "../../modules/EmployeeDetails/ViewEmployee/Partials/ViewEmployeeProfile";
import { EmployeeQualification } from "../../modules/EmployeeDetails/Qualifications";
import { ViewQualification } from "../../modules/EmployeeDetails/Qualifications/Partials/ViewQualification";
import { Requirement } from "../../modules/EmployeeDetails/Requirements";
import { AddUserMain } from "../../modules/AddUsers";
import AddUser from "../../modules/AddUsers/Partials/AddUser";
import { Department } from "../../modules/EmployeeDetails/ViewDepartments";
import Demo from "../../modules/Payroll/Demo";
import ViewLeave from "../../modules/Leave/Partials/ViewLeave";
import ViewHoliday from "../../modules/Holiday/Partials/HolidayView";
import { Promotions } from "../../modules/Promotions";
import { EmployeeComplaints } from "../../modules/Complaints";
import { DashboardContainer } from "../../modules/Dashboard";
import { ExtraTables } from "../../modules/EmployeeDetails/ExtraTables";
import { EmpExit } from "../../modules/ExitType/Exit";
import ViewDesignations from "../../modules/EmployeeDetails/ViewDesignations/Partials/ViewDesignations";
import ViewDepartments from "../../modules/EmployeeDetails/ViewDepartments/Partials/ViewDepartments";
import { ViewShift } from "../../modules/EmployeeDetails/ExtraTables/Partials/ViewShift";
import { Attendance } from "../../modules/Attendance";
import Payroll from "../../modules/Payroll/Partials";
import { EditAttendance } from "../../modules/Attendance/EditAttendance";
import { Training } from "../../modules/Training";
import { AllReportsIndex } from "../../modules/Reports";
import { ViewBusinessProfile } from "../../modules/BusinessProfile/Partials/ViewBusinessProfile";
import { Payslip } from "../../modules/Reports/PayrollReports/Partials/Payslip/Payslip";
import { ViewAdminAccount } from "../../modules/AdminAccount/Partials/ViewAdminAccount";
import { ViewUserDetails } from "../../modules/AddUsers/Partials/ViewUserDetails";
import { ViewUser } from "../../modules/AddUsers/Partials/ViewUser";
import { AttendanceReportPage } from "../../modules/Reports/AttendanceReports/Partials/AttendanceReports";
import ViewBasicSalary from "../../modules/Payroll/ViewBasicSalary/Partials/ViewBasicSalary";
import ViewPayroll from "../../modules/Payroll/ViewPayroll/Partials/ViewPayroll";



export const anonymous = [
    {
        routePath: '/signin',
        Component: UserSignin,
    },
]

export const adminAuthenticated = [

    // {
    //     routePath: '*',
    //     Component: PageNotFound,
    // },
    {
        routePath: '/',
        Component: DashboardContainer,
    },
    {
        routePath: 'user',
        Component: AddUserMain,
    },
    {
        routePath: 'business_profile',
        Component: ViewBusinessProfile,
    },
    {
        routePath: 'admin_profile',
        Component: ViewAdminAccount,
    },
    {
        routePath: 'view_user',
        Component: ViewUserDetails,
    },
    {
        routePath: 'adduser',
        Component: AddUser,
    },
    {
        routePath: 'user_detail',
        Component: ViewUser,
    },
    {
        routePath: 'viewprofile',
        Component: ViewEmploye,
    },
    {
        routePath: 'viewemployee/:id',
        Component: ViewEmployeeProfile,
    },
    {
        routePath: 'viewqualification',
        Component: EmployeeQualification,
    },
    {
        routePath: 'viewemployeequalification',
        Component: ViewQualification,
    },
    {
        routePath: 'requirement',
        Component: Requirement,
    },
    {
        routePath: 'extra_table',
        Component: ExtraTables,
    },
    {
        routePath: 'all_departments',
        Component: Department,
    },
    {
        routePath: 'basicsalary',
        Component: Demo,
    },
    {
        routePath: 'view_basicsalary',
        Component: ViewBasicSalary,
    },
    {
        routePath: 'payroll',
        Component: Payroll,
    },
    {
        routePath: 'view_payroll',
        Component: ViewPayroll,
    },
    {
        routePath: 'leave',
        Component: ViewLeave,
    },
    {
        routePath: 'holiday',
        Component: ViewHoliday,
    },
    {
        routePath: 'promotions',
        Component: Promotions,
    },
    {
        routePath: 'complaint',
        Component: EmployeeComplaints,
    },
    {
        routePath: 'view_designations',
        Component: ViewDesignations,
    },
    {
        routePath: 'view_departments',
        Component: ViewDepartments,
    },
    {
        routePath: 'view_shift',
        Component: ViewShift,
    },
    {
        routePath: 'attendance',
        Component: Attendance,
    },
    {
        routePath: 'editattendance',
        Component: EditAttendance,
    },
    {
        routePath: 'exit',
        Component: EmpExit,
    },
    {

        routePath: 'training',
        Component: Training,
    },
  {
        routePath: 'all_reports',
        Component: AllReportsIndex,
    },
    {
        routePath: 'payslip/:id',
        Component: Payslip,
    },
    {
        routePath: 'attendance_report',
        Component: AttendanceReportPage,
    },
]