import axios from 'axios';


const baseURLs = {

  //development: 'http://192.168.29.66:8090/', // API server url (Development)
  production: 'https://hrm.dev2.api.ideauxbill.in/', // API server url (Production)
  staging: 'https://hrm.dev2.ideauxbill.in/',  // API server url (Staging)
};

//const environment = process.env.NODE_ENV || 'development';
const environment = 'production';

const request = axios.create({
  baseURL: baseURLs[environment],     // Sanjay

  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export default request;


export const base = baseURLs[environment];     // Sanjay






