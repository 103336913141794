import React, { Fragment, useEffect, useRef, useState } from "react";
import Flex from "../../components/Flex";
import { Col, Row, Button as Buttons, Badge } from "antd";
import { NavTopDraw } from "./Style";
import { AiOutlineMenu, AiOutlineBell } from "react-icons/ai";
import Button from "../../components/Form/CustomButton";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../components/CustomModal";
import { logOut, selectCurrentRole, selectCurrentUser } from "../../modules/Auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  getNotification,
  selectAllNotifications,
} from "../../modules/Notifications/Partials/NotificationSlice";
import { BiUser } from "react-icons/bi";
import ProfileStyle from "./ProfileStyle";
import { Notifications } from "../../modules/Notifications/Partials/Notifications";
import { CustomBadge } from "../../components/CustomBadge";

const Styledcard = styled.div`
  padding: 10px;
  height: 100%;
  width: 100%;
`;

const Profiles = styled.div`
display: flex;
align-items: center;
margin: -5px 0 20px 0px;
margin-top: 2px;
gap: 10px;
padding: 5px 10px;
background-color: #fff;
box-shadow: rgb(0 0 0 / 14%) 0px 1px 2px 0px;
border-radius: 50%;

& svg {
    color: rgb(84, 84, 84);
}
:hover{
    & svg {
        color: #000;
    } 
}
`
const NotificationsAlertt = styled.div`
display: flex;
align-items: center;
margin: -5px 0 20px 0px;
margin-top: 2px;
margin-right: 18px;
gap: 10px;
padding: 5px 10px;
background-color: #fff;
box-shadow: rgb(0 0 0 / 14%) 0px 1px 2px 0px;
border-radius: 50%;

& svg {
    color: rgb(84, 84, 84);
}
:hover{
    & svg {
        color: #000;
    } 
}

`
const ImageProfile = styled.div`
  /* width:100%; */
  & img {
    width: 40px;
    margin: auto;
    border-radius: 50%;
  }
`;

export const NavHeader = ({ updateCollapse, showDrawer }) => {
  const dispatch = useDispatch();
  const notificationRef = useRef(null);
  const profileRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  useEffect(() => {
    dispatch(getNotification());
  }, []);

  const AllNotifications = useSelector(selectAllNotifications);
  
  useEffect(() => {
    setDataSource(AllNotifications);
  }, [AllNotifications]);

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // For Profile
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        // Click occurred outside the profile, so close it
        setIsProfileOpen(false);
      }
    };
    if (isProfileOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isProfileOpen]);

  // For Notification
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (notificationRef.current && !notificationRef.current.contains(e.target)) {
        // Click occurred outside the profile, so close it
        setIsNotificationOpen(false);
      }
    };
    if (isNotificationOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isNotificationOpen]);

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const toggleNotify = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };
  const ProfileName = useSelector(selectCurrentUser);
  const role = useSelector(selectCurrentRole)

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/addprofile");
  };

  const AdminLogOut = () => {
    setModalContent(<LogOutModal />);
    setModalTitle("Log Out");
    showModal();
  };

  const LogOutModal = () => (
    <div>
      <h1 style={{ fontSize: "1.2rem" }}>Are you Sure You Want to Logout ?</h1>
      <br />
      <Flex style={{ justifyContent: "center", gap: "20px" }}>
        <Button.Success text={"Logout"} onClick={Signout} />
        <Button.Danger text={"Cancel"} onClick={handleOk} />
      </Flex>
    </div>
  );

  const Signout = () => {
    dispatch(logOut());
    localStorage.removeItem("openKeys");
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const ViewNotification = () => {
    setModalContent(<Notifications />);
    setModalTitle("Notifications");
    showModal();
  };

  const items = [
    {
      key: "1",
      label: "My Account",
      onClick: () => {
        role === "Manager" ?
          navigate("/user_detail") :
          navigate("/admin_profile")
      },
    },
    {
      key: "2",
      label: "Log Out",
      onClick: () => {
        setModalContent(<LogOutModal />);
        setModalTitle("Log Out");
        showModal();
      },
    },
  ];

  const data = [
    {
      id: 1,
      message: "fsdgsdggsfdg",
      date: "22-10-23",
      event_date: "fghd",
    },
    {
      id: 2,
      message: "fsdgsdggsfdg",
      date: "22-10-23",
      event_date: "fghd",
    },
    {
      id: 3,
      message: "fsdgsdggsfdg",
      date: "22-10-23",
      event_date: "fghd",
    }, {
      id: 4,
      message: "fsdgsdggsfdg",
      date: "22-10-23",
      event_date: "fghd",
    },
    {
      id: 5,
      message: "fsdgsdggsfdg",
      date: "22-10-23",
      event_date: "fghd",
    },
  ];

  // const data = []

  // const notifications = (
  //   <Menu>
  //     <div
  //       style={{
  //         width: "100%",
  //         backgroundColor: "#0077b6",
  //         color: "#fff",
  //         display: "flex",
  //         justifyContent: "center",
  //         position: "sticky",
  //         top: "0px",
  //         padding: "12px 0px",
  //         zIndex: "1",
  //       }}
  //     >
  //       Show Notifications
  //     </div>
  //     {dataSource?.length > 0 ? (
  //       dataSource.map((item) => (
  //         <Menu.Item key={item.id}>
  //           <Styledcard>
  //             <div style={{ display: "flex", gap: "20PX" }}>
  //               <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  //                 <img src={SVGICONS.MAILSVG} style={{ width: "20px" }} alt="" />
  //               </div>
  //               <div>
  //                 <p style={{ fontSize: "16px" }}>{item.message || ""}</p>
  //                 <p style={{ fontSize: "14px" }}>{item.date || ""}</p>
  //                 <p>{item.event_date || ""}</p>
  //               </div>
  //             </div>
  //           </Styledcard>
  //         </Menu.Item>
  //       ))
  //     ) : (
  //       <p style={{ textAlign: "center", padding: "10px 0px" }}>No Notifications..</p>
  //     )}

  //   </Menu>
  // );

  return (
    <Fragment>
      <NavTopDraw>
        <Row>
          <Col span={4} md={4} className="DrawBtn">
            <Buttons type="text" onClick={showDrawer}>
              <AiOutlineMenu style={{ fontSize: "20px" }} />
            </Buttons>
          </Col>
          <Col span={0} md={14} className="SearchTop">
            {/* <Flex className="SearchTop">
              <CustomSearchBar
                placeholder={"Search in app..."}
                prefix={
                  <BsSearch
                    style={{
                      fontSize: "16px",
                      color: "#e1e1e1",
                      cursor: "pointer",
                    }}
                  />
                }
              />
            </Flex> */}
          </Col>
          <Col span={20} md={10}>
            {/* <Flex className="Btnresponsive" style={{ justifyContent: "end" }}>
              <Dropdown
                overlayStyle={{
                  width: "400px",
                  height: "300px",
                   overflow: "auto",
                  borderRadius: "10px",
                }}
                overlay={notifications}
                trigger={["click"]}
                placement="bottomCenter"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <div style={{ position: "relative" }}>
                    <AiOutlineBell
                      style={{
                        fontSize: "23px",
                        marginRight: "10px",
                        marginTop: "6px",
                      }}
                    />
                    {data.length > 0 && (
                      <span
                        style={{
                          background: "red",
                          borderRadius: "50%",
                          position: "absolute",
                          height: "7px",
                          width: "7px",
                          top: "7px",
                          left: "10px",
                        }}
                      ></span>
                    )}
                  </div>
                </a>
              </Dropdown>
              <Profiles>
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Flex>
                      <ImageProfile>
                        <img src={profile} alt="Profile" />
                      </ImageProfile>

                      <>
                        <p
                          style={{
                            transition: "0.5s",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            lineHeight: "1.3",
                            textTransform: "capitalize",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;{ProfileName}
                        </p>
                        &nbsp;
                      </>
                    </Flex>
                  </a>
                </Dropdown>
              </Profiles>
            </Flex> */}

            <Flex flexEnd={true}>

              <NotificationsAlertt>
                {
                  dataSource?.length > 0 ? (
                    <div ref={notificationRef}>
                      <a onClick={toggleNotify} href="#">
                        <Flex>

                          <div >
                            <AiOutlineBell style={{ fontSize: '20px', color: "black", position: 'relative' }} />
                            <Badge dot={'true'} style={{ position: "absolute", top: "-15px", right: "1px" }}
                            />
                          </div>
                        </Flex>
                      </a>
                      {isNotificationOpen && <Notifications />}
                    </div>) :
                    (
                      <Flex>
                        <AiOutlineBell style={{ fontSize: '20px'}} />
                      </Flex>
                   )
                }
              </NotificationsAlertt>

              <Profiles>
                <div ref={profileRef}>
                  <a onClick={toggleProfile} href="#open-profile">
                    <Flex alignCenter>
                      <ImageProfile>
                        <BiUser style={{ fontSize: '20px' }} />
                      </ImageProfile>
                    </Flex>
                  </a>
                  {isProfileOpen && <ProfileStyle />}
                </div>
              </Profiles>
            </Flex>

          </Col>

          <CustomModal
            isVisible={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            width={400}
            modalTitle={modalTitle}
            modalContent={modalContent}
          />
        </Row>
      </NavTopDraw>
    </Fragment >
  );
};
