import React from 'react'
import { ViewEmployeeQualificationTable } from './Partials/QualificationTable'

export const EmployeeQualification = () => {
  return (
    <div>
        <ViewEmployeeQualificationTable/>
    </div>
  )
}
