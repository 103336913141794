import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { adminAuthenticated } from '../config/user'
import Flex from '../../components/Flex'
import styled from 'styled-components'
import { DashLayout } from '../../layout/DashLayout'
import { logOut, selectCurrentRole, selectCurrentRoleID, selectCurrentUserId } from '../../modules/Auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import request from '../../utils/request'
import { toast } from 'react-toastify'

const PageFlex = styled(Flex)`
  overflow: hidden;
`

const AuthPage = ({ isAuthenticated }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const GETUSERLOGUTURL = 'logout'

    const RoleID = useSelector(selectCurrentRoleID)
    const RoleName = useSelector(selectCurrentRole)
    const UniqueCurrentId = useSelector(selectCurrentUserId);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/signin')
        }
        else{
            GetEmployeeExpire()
        }

    }, [isAuthenticated, location])

    // useEffect(() => {
    //     GetEmployeeExpire()
    // }, [])
    
    const GetEmployeeExpire = () => {

        request.get(`${GETUSERLOGUTURL}/${UniqueCurrentId}/${RoleID}/${RoleName}`)
            .then(function (response) {
                if (response?.data?.status === false) {
                    toast.warn("User Expire!")
                    console.log(response, 'expire');
                    dispatch(logOut());
                }
                else {
                    console.log(response, 'notexpired');
                }
            })
            .catch(function (error) {
                console.log(error, 'emlogerrr');

            });
    }
    return (
        <PageFlex>
            {isAuthenticated && (
                <DashLayout>
                    <Routes>
                        {adminAuthenticated.map(({ routePath, Component }) => {
                            return (
                                <Route
                                    key={routePath}
                                    path={routePath}
                                    element={<Component />}
                                ></Route>
                            )
                        })}
                    </Routes>
                </DashLayout>
            )}
        </PageFlex>
    )
}

export default AuthPage
