import React, { Fragment, useEffect, useState } from 'react'
import { CustomTable } from '../../../../components/Form/CustomTable'
import { CustomPageFormTitle, CustomPageTitle } from '../../../../components/CustomPageTitle';
import { CustomRow } from '../../../../components/CustomRow';
import { Col, Form, Popconfirm } from 'antd';
import CustomInputSearch from '../../../../components/Form/CustomInputSearch';
import Flex from '../../../../components/Flex';
import Button from '../../../../components/Form/CustomButton';
import { FiEdit, FiPlus } from 'react-icons/fi';
import EmployeeExit from './EmployeeExit';
import { TableIconHolder } from '../../../../components/CommonStyled';
import { CustomModal } from '../../../../components/CustomModal';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import { CustomStandardTable } from '../../../../components/Form/CustomStandardTable';
import { useDispatch, useSelector } from 'react-redux';
import { getResignation, selectAllResignation } from '../../Resignation/Partials/ResignationSlice';
import { selectAllDesignation } from '../../../EmployeeDetails/EmployeeSlice';
import { THEME } from '../../../../theme';
import { CustomLableBack } from '../../../../components/CustomLableBack';

const EmployeeExitTable = () => {

    const dispatch = useDispatch()


    const [searchTexts, setSearchTexts] = useState([]);
    const [dataSource, setDataSource] = useState([])

    const [trigger, setTrigger] = useState(0)

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        // FormRest();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        // FormRest();
    };

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const FormExternalClose = () => {
        handleOk();
    };

    const AddUserDetails = () => {
        setTrigger(trigger + 1)
        setModalTitle("Add Exit Type");
        setModalContent(
            <EmployeeExit trigger={trigger} FormExternalClose={FormExternalClose} />
        );
        showModal();
    };

    const EditExitEmp = (record) => {
        setTrigger(trigger + 1)
        setModalTitle("Edit Exit Type");
        setModalContent(
            <EmployeeExit
                updateRecord={record}
                formname={"AddEmployeeComplaintForm"}
                FormExternalClose={FormExternalClose}

            />
        );
        showModal();
    };

    useEffect(() => {
        dispatch(getResignation())

    }, [])

    const resignationTable = useSelector(selectAllResignation)

    useEffect(() => {
        setDataSource(resignationTable)

    }, [resignationTable])




    const columns = [
        {
            title: "SI No",
            render: (value, item, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "user_name",
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return (
                    String(record.user_name)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.user_name).includes(value.toUpperCase())
                );
            },
        },
        {
            title: "User ID",
            dataIndex: "user_id",
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Date",
            dataIndex: "resignations_date",
        },
        {
            title: "Action",
            render: (record, i) => {
                return (
                    <Flex center={"true"} gap={"10px"}>
                        {/* <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { UpdateParty(record) }}> */}
                        <TableIconHolder color={THEME.blue} size={"22px"}>
                            <FiEdit onClick={() => { EditExitEmp(record) }} />
                        </TableIconHolder>

                        {/* <Popconfirm
                            record={record}
                            title={"Delete the party List"}
                            description={"Are you sure to delete this party ?"}
                        >
                            <TableIconHolder color={THEME.red} size={"22px"}>
                                <RiDeleteBinLine />
                            </TableIconHolder>
                        </Popconfirm> */}
                    </Flex>
                );
            },
        },
    ]


    return (
        <Fragment>
            <Flex>
                <CustomLableBack />
                <CustomPageTitle Heading={"Exit"} />
            </Flex>

            <CustomRow
                space={[24, 24]}
                style={{ background: "#dae1f3", paddingTop: "12px" }}
            >
                <Col span={24} md={10} style={{ display: "flex", gap: "10px", alignItems: 'baseline' }}>
                    <CustomPageFormTitle Heading={"Exit"} />
                    <CustomInputSearch
                        placeholder={"search ..."}
                        value={searchTexts}
                        onChange={(e) => handleSearchs(e.target.value)}
                    />
                </Col>
                <Col span={24} md={14}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}></Col>
                        <Col span={24} md={8} style={{ float: "right" }}>
                            <Flex style={{ marginRight: "-30px", justifyContent: "end" }}>
                                <Button.Primary
                                    style={{ borderRadius: "6px" }}
                                    icon={<FiPlus style={{ fontSize: "20px" }} />}
                                    text={"Add"}
                                    onClick={AddUserDetails}
                                />
                            </Flex>
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
            <CustomStandardTable
                columns={columns}
                data={dataSource}
            />
            {/* {content} */}
            <CustomModal
                isVisible={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                width={800}
                modalTitle={modalTitle}
                modalContent={modalContent}
            />

        </Fragment>
    )
}

export default EmployeeExitTable