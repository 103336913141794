// import { Card } from "antd";
// import styled from "styled-components";

import styled from "styled-components";

// export const StyledNotification = styled(Card)`

// background-color: #f6f5f7;

// & h6{
// color: #441f69;
// }

// & p{
// color: #6e0e46
// }

// & span{
//     color: #6e0e46;
// }
// `

export const StyledNotification = styled.div`
    position: absolute;
    top: 80%;
    right: 10%;
    width: 350px;
    z-index: 1;
    color: var(--light-color);
    /* background-color: #F6F5F7; */

    .notifyAlert {
        background-color: #ddebe0;
        border: 1px solid rgb(219, 205, 205);
        box-shadow: rgb(0 0 0 / 6%) 0px 8px 10px;
        padding: 20px;
        margin: 10px;
        border-radius: 0 10%;
    }

    & h1 {
        text-align: center;
        border-bottom: 1px solid rgb(112 103 103 / 20%);
        padding-bottom: 15px;
    }
    
    .notifIcon {
        background: #e8e8e8;
        padding: 5px 5px 0px 5px;
        border-radius: 50%;
        & svg {
            color: #000;
        }
    }

& img{
    margin: auto;
    border-radius: 50%;
    width: 40px;
}
 /* width: 250px; */

& h6{
color: #441f69;
}

& p{
color: blueviolet
}

& span{
    color: #6e0e46;
}

@media screen and (max-width:376px) {
    
    top: 80%;
    right: -20px;
    width: 328px;

}
`