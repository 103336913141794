import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import profiles from '../../Images/profile.png'
import logout from '../../Images/logout.png'
import Flex from '../../components/Flex'
import { logOut, selectCurrentRole, selectCurrentRoleID, selectCurrentUserId } from '../../modules/Auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { CustomModal } from '../../components/CustomModal'
import Button from '../../components/Form/CustomButton'
import { useNavigate } from 'react-router-dom'
import { getAdminAccount, selectAllAdminAccount } from '../../modules/AdminAccount/AdminAccountSlice'
import { APIURLS } from '../../utils/ApiUrls'
import request, { base } from '../../utils/request'


export const SubMenuWrap = styled.div`
    position: absolute;
    top: 80%;
    right: 10%;
    width: 270px;
    z-index: 1;
    max-height: 0px;
    border-radius: 10px;
    color: var(--light-color);
 
`;

export const SubMenu = styled.div`
background-color: white;
border: 1px solid rgb(219, 205, 205);
box-shadow: rgb(0 0 0 / 6%) 0px 8px 10px;
padding: 20px;
margin: 10px;
border-radius: 0 10%;

& hr {
    border: 0;
    height: 1px;
    width: 100%;
    background-color: #ccc;
    margin: 15px 0 10px;
}
`

const UserInfo = styled.div`
display: flow-root;
justify-content: center;
text-align: center;

& h2{
    font-weight: 900;
    margin: 5px 0;
    font-size: 20px;
}
& img {
    width: 70px;
    border-radius: 50%;
}
& h5 {
    font-size: 15px;
    margin: 5px 0;
}


`

const Submenulink = styled.div`
display: flex;
align-items: center;
text-decoration: none;
color: #525252;
margin: 12px 0;

& p {
    width: 100%;
}

& p:hover {
    font-size: 15px;
    cursor: pointer;
  }


& img {
    width: 40px;
    background-color: #e5e5e5;
    border-radius: 50%;
    padding: 8px;
    margin-right: 15px;
    cursor: pointer;

}

& span {
    font-size: 22px;
}


`

const ProfileStyle = () => {

    // const ProfileName = useSelector(selectCurrentUser)
    const role = useSelector(selectCurrentRole)
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [setDetails, setDetailsGet] = useState([])

    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("");
        showModal();
    }
    const userID = useSelector(selectCurrentUserId)
    const RoleID = useSelector(selectCurrentRoleID)


    useEffect(() => {
        dispatch(getAdminAccount())
    }, [])

    useEffect(() => {
        GetUserDetaisss();
    }, []);

    const GetUserDetaisss = () => {

        request
            .get(`${APIURLS.GETLOGINDETAILS}/${userID}/${RoleID}`)
            .then(function (response) {
                setDetailsGet(response.data);
            })
            .catch(function (error) {
            });
    };

    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: "1.2rem" }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex style={{ justifyContent: "center", gap: "20px" }}>
                <Button.Success text={"Logout"} onClick={Signout} />
                <Button.Danger text={"Cancel"} onClick={handleOk} />
            </Flex>
        </div>
    );

    const Signout = () => {
        dispatch(logOut());
        localStorage.removeItem("openKeys");
    };


    const handleOk = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate()

    const handleClick = () => {
        role === "Manager" ?
            navigate("/user_detail") :
            navigate("/admin_profile")
    };



    return (
        <SubMenuWrap>
            <SubMenu>
                <UserInfo>
                    <img src={`${base}${setDetails?.image}`} alt="Profile" preview={false} />
                </UserInfo>
                <hr></hr>

                <Submenulink href='#' >
                    <img src={profiles} preview={false} />
                    <p onClick={handleClick}>My Account</p>
                    {/* <span> <- </span> */}
                </Submenulink>

                {/* <Submenulink href='#' >
                    <img src={profiles} />
                    <p>Edit Profile</p>
                </Submenulink> */}

                <Submenulink href='#' className='submenulink'>
                    <img src={logout} preview={false} />
                    <p onClick={AdminLogOut}>Logout</p>
                    {/* <span> > </span> */}
                </Submenulink>

            </SubMenu>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={400}
                modalTitle={modalTitle} modalContent={modalContent} />
        </SubMenuWrap>
    )
}

export default ProfileStyle
