import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";
import { APIURLS } from "../../utils/ApiUrls";

const initialState = {
  employeeCount: [],
  department: [],
  dashboard: {},
  gender:[],
  attendance:[],
  absenttable:[],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// get employeecount

export const getEmployeeCount = createAsyncThunk(
  "employeecount/get",
  async () => {
    try {
      const employees = "count";
      const response = await request.get(`${APIURLS.GETEMPLOYEECOUNT}`, {
        params: { employees },
      });
      return [...response.data];
    } catch (error) {
      // throw error;
    }
  }
);

//get Departments

export const getDepartments = createAsyncThunk("departments/get", async () => {
  try {
    const employees = "department";
    const response = await request.get(`${APIURLS.GETDASHDEPARTMENT}`, {
      params: { employees },
    });
    return [...response.data];
  } catch (error) {
    // throw error;
  }
});

//get Dashboards

export const getDashboard = createAsyncThunk("dashboard/get", async () => {
  try {
    const attendance = "dashboardcount";
    const response = await request.get(`${APIURLS.GETDASHBOARDDETAIL}`, {
      params: { attendance },
    });
    return response.data;
  } catch (error) {
    // throw error;
  }
});

//get gender

export const getgender = createAsyncThunk("gender/get", async () => {
  try {
    const employees = "other";
    const response = await request.get(`${APIURLS.GETGENDERDETAIL}`, {
      params: { employees },
    });
    return response.data;
  } catch (error) {
    // throw error;
  }
});

//get attendance

export const getAttendance = createAsyncThunk("attendance/get", async () => {
  try {
    const attendance1 = "percentage";
    const response = await request.get(`${APIURLS.GETATTENDANCEPERCENTAGE}`, {
      params: { attendance1 },
    });
    return response.data;
  } catch (error) {
    // throw error;
  }
});

//get absentTable

export const getAbsentTable = createAsyncThunk("absentTable/get", async () => {
  try {
    const attendance1 = "absent";
    const response = await request.get(`${APIURLS.GETABSENTTABLE}`, {
      params: { attendance1 },
    });
    return response.data;
  } catch (error) {
    // throw error;
  }
});

const dashboardSlice = createSlice({
  name: "employeedetails",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      // employee count

      .addCase(getEmployeeCount.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEmployeeCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employeeCount = action.payload;
      })
      .addCase(getEmployeeCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // department

      .addCase(getDepartments.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.department = action.payload;
      })
      .addCase(getDepartments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // dashboard

      .addCase(getDashboard.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = action.payload;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

       // gender

       .addCase(getgender.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getgender.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.gender = action.payload;
      })
      .addCase(getgender.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

        // attendance

        .addCase(getAttendance.pending, (state, action) => {
          state.status = "loading";
        })
        .addCase(getAttendance.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.attendance = action.payload;
        })
        .addCase(getAttendance.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        })

         // absent table

         .addCase(getAbsentTable.pending, (state, action) => {
          state.status = "loading";
        })
        .addCase(getAbsentTable.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.absenttable = action.payload;
        })
        .addCase(getAbsentTable.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        })
  },
});

// EmployeeCount

export const selectAllEmployeeCount = (state) => state.employeeCountt.employeeCount;
export const getEmployeeCountStatus = (state) => state.employeeCountt.status;
export const getEmployeeCountError = (state) => state.employeeCountt.error;

// Department

export const selectAllDepartment = (state) => state.employeeCountt.department;
export const getDepartmentStatus = (state) => state.employeeCountt.status;
export const getDepartmentError = (state) => state.employeeCountt.error;

// Dashboard

export const selectAllDashboard = (state) => state.employeeCountt.dashboard;
export const getDashboardStatus = (state) => state.employeeCountt.status;
export const getDashboardError = (state) => state.employeeCountt.error;

// gender

export const selectAllGender = (state) => state.employeeCountt.gender;
export const getGenderStatus = (state) => state.employeeCountt.status;
export const getGenderError = (state) => state.employeeCountt.error;

// attendance

export const selectAllAttendance = (state) => state.employeeCountt.attendance;
export const getAttendanceStatus = (state) => state.employeeCountt.status;
export const getAttendanceError = (state) => state.employeeCountt.error;

// absent table

export const selectAllAbsentTable = (state) => state.employeeCountt.absenttable;
export const getAbsentTableStatus = (state) => state.employeeCountt.status;
export const getAbsentTableError = (state) => state.employeeCountt.error;

export const { reducer } = dashboardSlice;
export const { addEmployeeCount } = dashboardSlice.actions;

export default dashboardSlice.reducer;
