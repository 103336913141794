import React, { useState } from 'react'
import { StyledUser, UserCard, UserImageCard } from '../style'
import { CustomRow } from '../../../components/CustomRow'
import { Col } from 'antd'
import Flex from '../../../components/Flex'
import { CustomModal } from '../../../components/CustomModal'
import { base } from '../../../utils/request'

export const ViewUserDetails = ({viewrecord}) => {

    const [formReset, setFormReset] = useState(0);

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormRest()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        FormRest()
    };

    const FormRest = () => {
        setFormReset(formReset + 1);
    };

    const FormExternalClose = () => {
        handleOk();

    };

    return (
        <StyledUser>
            <Flex center={'true'}>
                <UserImageCard>
                    <img
                        src={`${base}${viewrecord?.url}`}
                        alt="img"
                        width={100}
                        height={100}
                        style={{ borderRadius: "25%", objectFit: "cover" }}
                    />
                </UserImageCard>
            </Flex>

            <CustomRow space={[12, 12]}>

                <Col span={24} md={12} >
                    <UserCard>
                        <CustomRow>
                            <Col span={24} md={12} >
                                <h1>USER NAME :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.username}</p>
                            </Col>
                            <Col span={24} md={12}>
                                <h1>Mobile Number :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.mobileNumber}</p>
                            </Col>
                            <Col span={24} md={12}>
                                <h1>Email ID :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.email}</p>
                            </Col>
                            <Col span={24} md={12}>
                                <h1>Role Type :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.roleType}</p>
                            </Col>
                        </CustomRow>
                    </UserCard>
                </Col>

                <Col span={24} md={12}>
                    <UserCard>
                        <CustomRow>
                            <Col span={24} md={12} >
                                <h1>Address :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.address}</p>
                            </Col>
                            <Col span={24} md={12}>
                                <h1>Location :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.location}</p>
                            </Col>
                            <Col span={24} md={12}>
                                <h1>City :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.city}</p>
                            </Col>
                            <Col span={24} md={12}>
                                <h1>Country :</h1>
                            </Col>
                            <Col span={24} md={12}>
                                <p>{viewrecord?.country}</p>
                            </Col>
                        </CustomRow>
                    </UserCard>
                </Col>
            </CustomRow>
            <CustomModal
                isVisible={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                width={800}
                modalTitle={modalTitle}
                modalContent={modalContent}
            />
        </StyledUser>
    )
}
