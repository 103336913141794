import { Card } from "antd";
import styled from "styled-components";

export const StyledDetails = styled.div`

.paraBlue {
    color: #0d6efd;
    font-size: 100%;
    line-height: 25px;
    padding-left: 20px;
  }
`

// jai
