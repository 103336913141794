import { Card } from "antd";
import styled from "styled-components";

export const StyledQualificationCards = styled(Card)`

/* height: 250px; */

`

export const StyledID = styled.div`

margin-top: 10px;
text-align: center;
`

export const StyledQualificationCards1 = styled(Card)`

/* height: 250px; */
& h1{
    margin-top: 25px;
    text-align: center;
    cursor: pointer;
}

`

export const StyledQualificationCards2 = styled(Card)`

height: 265px;
display: flex;
justify-content: center;
align-items: center;
& h1{
    margin-top: 15px;
    text-align: center;
}

`

export const StyledQualificationCards3 = styled(Card)`

height: 265px;
display: flex;
justify-content: center;
align-items: center;
& h1{
    margin-top: 15px;
    text-align: center;
}

`