import React, { useEffect } from 'react'
import styled from 'styled-components'
import Flex from '../../../components/Flex'
import SignInForm from './SignInForm'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCredentials, selectCurrentUser } from '../authSlice'
import request from '../../../utils/request'
import { toast } from 'react-toastify'
import initializeApp from '../../../utils/initializeApp'


export const Wrapper = styled(Flex)`
  height: 100vh;
  width: 100%;
  background: whitesmoke;
`

const UserSignin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const handleSignIn = async (data) => {
    try {
      const authData = await request.post(`login`, {
        ...data,

      })
      if (authData?.data?.token !== '') {
        initializeApp(authData?.data?.token)
        toast.success(`Login Successful, Welcome ${authData?.data?.userName}`)
        dispatch(setCredentials(authData?.data))
        console.log(authData?.data,'daaaaaaataaaa');
        navigate('/')
      }
      else {
        toast.error('UserName or Password is incorrect ')
      }

    } catch (error) {
      console.log(error,'error');
      if (error.response?.status && error.response?.status === 403) {
        toast.error(error.response?.data )
      }
      else {
        toast.error('Getting error while login, Please Login Again')
      }
    }
  }

  const token = useSelector(selectCurrentUser);


  useEffect(() => {
    if (token) {
      navigate('/signin')
    }
  }, [token])

  return (
    <Wrapper column={'true'}>
      <SignInForm handleSignIn={handleSignIn} />
    </Wrapper>
  )
}
export default UserSignin

