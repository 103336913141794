import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";
import { APIURLS } from "../../utils/ApiUrls";


const initialState = {
  training: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// get Training

export const getTraining = createAsyncThunk(
  "holiday/get",
  async () => {
    try {
      const trainee = 'traineeDetails';
      const response = await request.get(`${APIURLS.GETTRAINING}`, {
        params: { trainee }
      })
      return [...response.data];
    } catch (error) {
      // throw error;
    }
  });

const trainingSlice = createSlice({
  name: "trainingdetails",
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder

      // Training

      .addCase(getTraining.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getTraining.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.training = action.payload;
      })
      .addCase(getTraining.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

// Training

export const selectAllTraining = (state) => state.trainingg.training;
export const getTrainingStatus = (state) => state.trainingg.status;
export const getTrainingError = (state) => state.trainingg.error;

export const { reducer } = trainingSlice;
export const { addtraining } = trainingSlice.actions;

export default trainingSlice.reducer;
