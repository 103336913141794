import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { EmployeeCountData } from "./DashboardData";
import { StyledChart } from "../style";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeCount, selectAllEmployeeCount } from "../DashboardSlice";

export const BarGraph = () => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployeeCount());
  }, []);

  const AllEmployeeCount = useSelector(selectAllEmployeeCount);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const year = AllEmployeeCount?.map((item) => {
      return item.year;
    });
    const TotalEmp = AllEmployeeCount?.map((item) => {
      return item.total_employee_count;
    });
    const NewEmp = AllEmployeeCount?.map((item) => {
      return item.new_employee_count;
    });
    const options = {
      title: {
        text: "Employee Count",
      },
      legend: {},
      tooltip: {},
      xAxis: { type: "category", data: year || [0] },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: TotalEmp || [0],
          type: "bar",
        },
        {
          data: NewEmp || [0],
          type: "bar",
        },
      ],
    };

    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [AllEmployeeCount]);
  return <StyledChart ref={chartRef}></StyledChart>;
};
