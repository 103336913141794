import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { StyledChart } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments, selectAllDepartment } from '../DashboardSlice';

export const PieChart = () => {
    const chartRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getDepartments())
    }, [])
    
  
    const AllDepartments = useSelector(selectAllDepartment);

    const pieChartData = AllDepartments?.map(item => ({
      value: item.employee_count,
      name: item.department_name,
      itemStyle: {
        color: item.color,
      },
    }));

    useEffect(() => {
      const chart = echarts.init(chartRef.current);
  
      const options = {
        title: {
          text: 'Department Count',
          left: 'center',
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '60%',
            data: pieChartData || [0],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
  
      chart.setOption(options);
  
      return () => {
        chart.dispose();
      };
    }, [pieChartData]);
    return (
        <StyledChart ref={chartRef}></StyledChart>
      )
}


