import React, { Fragment } from 'react'
import { EditContainer } from './Partials/EditContainer'

export const EditAttendance = () => {
  return (
    <Fragment>
        <EditContainer/>
    </Fragment>
  )
}
