import React from 'react'
import { ViewEmployeeTable } from './Partials/ViewEmployeeTable'
import Flex from '../../../components/Flex'
import { CustomLableBack } from '../../../components/CustomLableBack'

export const ViewEmploye = () => {
  return (
      <ViewEmployeeTable/>
  )
}
