import React, { Fragment, useEffect, useState } from 'react'
import { CustomHolder } from './Style'
import { AddSearch } from './AddSearch'
import { AttendanceTable } from './AttendanceTable'
import request from '../../../utils/request'
import { APIURLS } from '../../../utils/ApiUrls'
import { attendancetype } from '../../../components/SampleData'
import { useDispatch, useSelector } from 'react-redux'
import { getShift, selectAllShift } from '../../EmployeeDetails/EmployeeSlice'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { CustomLableBack } from '../../../components/CustomLableBack'

export const AttendanceContainer = () => {

    const [searchedData, setSearchedData] = useState({})  // --> 
    const [attendanceData, setAttendanceData] = useState([])
    const [tableData, setTableData] = useState([])
    const [shiftOpt, setShiftOpt] = useState([])
    const [shiftData, setShiftData] = useState([])

    const [sendDate, setSendDate] = useState(dayjs().format("YYYY-MM-DD"));

    const [resetAll, setResetAll] = useState(0)

    const dispatch = useDispatch();

    const onChangeSearch = (arg) => {
        setSearchedData(arg)
    }

    const changeSendDate = (arg) => {
        setSendDate(arg)
    }
    useEffect(() => {
        if (Object.entries(searchedData).length != 0) {
            fetchData(searchedData);
        }
    }, [searchedData])

    useEffect(() => {
        if (attendanceData.length != 0) {
            const attData = attendanceData[0];
            console.log(attData, 'attData');
            if (Object.entries(attData).length != 0) {
                if (attData?.shiftName === attendancetype[0].value) {
                    setShiftData([])
                    setTableData(attData?.attendanceDetails)
                    console.log(tableData,'tableData');
                } else {
                    const attenData = attData?.attendanceType?.filter((item) => item?.shiftId === searchedData?.shiftOpt);

                    if (attenData.length != 0) {
                        setShiftData(attData?.attendanceType)
                        setTableData(attData?.attendanceType)
                        console.log(shiftData,'shiftData');
                    } else {
                        setShiftData([])
                        setTableData([])
                    }
                }
            }
        }
        else {
            setShiftData([])
            setTableData([])
        }
    }, [attendanceData])


    useEffect(() => {
        dispatch(getShift())
    }, [])

    const AllShiftDetails = useSelector(selectAllShift)

    useEffect(() => {
        setShiftOpt(AllShiftDetails)
    }, [AllShiftDetails])

    useEffect(() => {
        if (resetAll) {
            setTableData([]);
            setShiftData([]);
            setSearchedData({});
        }
    }, [resetAll])


    const fetchData = (value) => {
        request.get(`${APIURLS?.GETATT}`, {
            params: {
                employeesParam: 'employees',
                attendanceParam: value?.attenType,
                shiftId: value?.shiftOpt || 0,
            }
        })
            .then(function (response) {
                setAttendanceData(response.data)
            })
            .catch(function (error) {
            });

    }

    return (
        <Fragment>
            <CustomLableBack />
            <CustomHolder width={'600px'}>
                <AddSearch handleChange={onChangeSearch} shiftOpt={shiftOpt} setResetAll={setResetAll} changeSendDate={changeSendDate} />
            </CustomHolder>

            {
                Object.entries(searchedData).length != 0 && (
                    <CustomHolder mt={'20px'}>
                        <AttendanceTable data={tableData} shiftData={shiftData} sendDate={sendDate} />
                    </CustomHolder>
                )
            }
        </Fragment>
    )
}

