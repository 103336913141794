import React, { Fragment } from 'react'
import { AttendanceContainer } from './Partials/AttendanceContainer'

export const Attendance = () => {
  return (
    <Fragment>
      <AttendanceContainer/>
    </Fragment>
  )
}
