import React from 'react'
import ViewPromotions from './Partials/ViewPromotions'

export const Promotions = () => {
  return (
    <div>
        <ViewPromotions />
    </div>
  )
}
