import { Col, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../../components/CustomRow'
import { useForm } from 'antd/es/form/Form'
import Flex from '../../../../components/Flex'
import Button from '../../../../components/Form/CustomButton'
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber'
import { CustomModal } from '../../../../components/CustomModal'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomTextArea } from '../../../../components/Form/CustomTextArea'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import request from '../../../../utils/request'
import { APIURLS } from '../../../../utils/ApiUrls'



export const EmergencyContactsForm = ({ FormExternalClose, trigger, formname, Record, formReset,id,emerContact,GetEmergencyContact }) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dataSource, setDataSource] = useState([]);

    const dispatch = useDispatch()

    //=========Modal title and content ============//
    const [modalTitle, setModalTitle] = useState("")
    const [modalContent, setModalContent] = useState(null)
    const [form] = useForm()

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false);
    }

    const FormExternalClosee = () => {
        handleOk();
    }


    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const onReset = () => {
        form.resetFields()
    }

    const onFinish = (values) => {
        request
      .put(`${APIURLS.PUTEMERGENCYDETAILS}${id}/`, values)
      .then((response) => {
        toast.success("Emergency contact Details Update Successfully !");
        GetEmergencyContact();
        FormExternalClose();
      })
      .catch((error) => {
      });
    };

    const onFinishFailed = (errorInfo) => {
    }

    useEffect(() => {
      form.setFieldsValue(emerContact)
    }, [emerContact,trigger])
    

    return (
        <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name={formname}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <CustomRow space={[12, 12]}>

                <Col span={24} md={12}>
                    <CustomInput label={'Employee Relation Name'} name={'relatinoName'} placeholder={'Relation Name'} rules={[
                        {
                            required: true,
                            message: 'Please enter Relation Name !',
                        }
                    ]} />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber label={'Phone Number'} name={'phoneNumber'} placeholder={'Phone Number'} maxLength={10}
                        minLength={10}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Phone Number !',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <CustomTextArea label={'Address'} name={'address'} placeholder={'Address'} rules={[
                        {
                            required: true,
                            message: 'Please enter Address !',
                        }
                    ]} />
                </Col>

            </CustomRow>

            <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                {Record ? (
                    <>
                        <Button.Primary text={'Update'} htmlType={'submit'} />
                        <Button.Danger text={'Cancel'} onClick={() => FormExternalClose()} />
                    </>
                ) : (
                    <>
                        <Button.Success text={'Submit'} htmlType={'submit'} />
                        <Button.Danger text={'Reset'} onClick={() => onReset()} />
                    </>
                )}
            </Flex>

            <CustomModal isVisible={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} modalTitle={modalTitle} modalContent={modalContent} />

        </Form>
    )
}
