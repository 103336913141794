

import React, { useEffect, useState } from 'react'
import { StyledNotification } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { getNotification, selectAllNotifications } from './NotificationSlice'
import { CustomCardView } from '../../../components/CustomCardView'
import { PiDotOutline } from 'react-icons/pi'



export const Notifications = () => {

    const dispatch = useDispatch()


    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Products',
            dataIndex: 'product_name',
            key: 'product_name'
        },
        {
            title: 'Item Code',
            dataIndex: 'item_code',
            key: 'item_code'
        }
    ]

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        dispatch(getNotification())
    }, [])

    const AllNotifications = useSelector(selectAllNotifications)
    console.log(AllNotifications, 'AllNotificationsAllNotifications');
    useEffect(() => {
        setDataSource(AllNotifications)
    }, [AllNotifications])

    return (
        <StyledNotification>
            <div className='notifyAlert'>
                <div>
                <h1 >Notifications</h1><br />
                </div>

                {/* <CustomCardView> */}

                    {dataSource?.map((item, index) => (
                        <div key={index}>
                            
                            <h6 style={{color:"#441F69"}}><span ><PiDotOutline style={{color:"#47ad81"}}/>&nbsp;</span>{item?.message ? `${item?.message}` : ''}&nbsp;</h6>
                            <p>
                                {/* {item.first_name && item.last_name ? `${item.first_name} ${item.last_name}` : ''} */}
                                {/* {item.project_title ? ` ${item.project_title}` : ''} */}
                                <span>{item?.date ? `${item.date}` : ''}</span>&nbsp;[{item?.event_date}]
                            </p>
                            <p style={{color:"#c4d1c0"}}>-----------------------------------------</p>
                        </div>
                    ))}
                {/* </CustomCardView> */}
            </div>
        </StyledNotification>
    )
}
