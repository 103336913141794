import React from 'react'
import ViewDesignations from '../ViewDesignations/Partials/ViewDesignations'
import ViewDepartments from '../ViewDepartments/Partials/ViewDepartments'
import { ViewShift } from './Partials/ViewShift'
import { CustomTabs } from '../../../components/CustomTabs'

export const ExtraTables = () => {
  const tabs = [
    { label: 'Designations', content: <ViewDesignations /> },
    { label: 'Departments', content:     <ViewDepartments />},
    { label: 'Shifts', content:  <ViewShift /> },
  ];

  const onChangeTabs =()=>{

  }
  
  return (
    <div>
      {/* <CustomLableBack /> */}
        <CustomTabs tabs={tabs} defaultActiveKey={'1'} onChange={onChangeTabs}/>
    </div>
  )
}
