import React, { useEffect, useState } from 'react'
import { CustomPageFormTitle, CustomPageTitle } from '../../../components/CustomPageTitle'
import { CustomRow } from '../../../components/CustomRow'
import CustomInputSearch from '../../../components/Form/CustomInputSearch'
import { Col } from 'antd'
import Button from '../../../components/Form/CustomButton'
import { FiEdit, FiPlus } from 'react-icons/fi'
import { CustomModal } from '../../../components/CustomModal'
import { ComplaintsForm } from './ComplaintsForm'
import Flex from '../../../components/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { CustomStandardTable } from '../../../components/Form/CustomStandardTable'
import { getComplaints, getComplaintsError, getComplaintsStatus, selectAllComplaints } from '../ComplaintsSlice'
import { CommonLoading } from '../../../components/CommonLoading'
import { CustomLableBack } from '../../../components/CustomLableBack'
import { TbEye } from 'react-icons/tb'
import { ViewDescription } from './ViewDescription'

export const ViewComplaints = () => {
  const [dataSource, setDataSource] = useState([])
  const [searchTexts, setSearchTexts] = useState([]);
  const [formReset, setFormReset] = useState(0);
  const [trigger, setTrigger] = useState(0)
  const [widthsize,setWidthSize] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getComplaints());
  }, [])

  const AllComplaints = useSelector(selectAllComplaints);
  const AllComplaintsStatus = useSelector(getComplaintsStatus);
  const AllComplaintsErrors = useSelector(getComplaintsError);

  useEffect(() => {
    setDataSource(AllComplaints)
  }, [AllComplaints])

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  // ===== Modal Functions Start =====

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    FormRest()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const FormRest = () => {
    setFormReset(formReset + 1);
  };

  const FormExternalClose = () => {
    handleOk();
  };

  const handleSearchs = (value) => {
    setSearchTexts(value);
  };

  const UpdateComplaints = (record) => {
    setWidthSize(800)
    setTrigger(trigger + 1)
    setModalTitle('Update Complaint Details');
    setModalContent(<ComplaintsForm FormExternalClosee={FormExternalClose} formname={'editcomplaints'} formReset={formReset} complaintsrecord={record} updatetrigger={trigger} />);
    showModal()
  }

  const AddEmployeeComplaints = () => {
    setWidthSize(800)
    setModalTitle("Add Staff Complaints");
    setModalContent(
      <ComplaintsForm
        formname={"AddEmployeeComplaintForm"}
        FormExternalClosee={FormExternalClose}
        formReset={formReset}
      />
    );
    showModal();
  };

  const ViewDescriptionn = (record) => {
    setWidthSize(400)
    setModalTitle("View Description");
    setModalContent(
      <ViewDescription
        formname={"View Description"}
        FormExternalClosee={FormExternalClose}
        formReset={formReset}
        viewdesrec={record}
      />
    );
    showModal();
  };

  const columns = [
    {
      title: "SI No",
      render: (value, item, index) => index + 1,
    },
    {
      title: "User Id",
      dataIndex: "userId",
    },
    {
      title: "Name",
      dataIndex: "userName",
      filteredValue: searchTexts ? [searchTexts] : null,
      onFilter: (value, record) => {
        return (
          String(record.userName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.userName).includes(value.toUpperCase())
        );
      },
    },
    {
      title: "Complaints Title",
      dataIndex: "complaintsTitle",
    },
    {
      title: "Complaints Date",
      dataIndex: "complaintsDate",
    },
    {
      title: "Complaints Against",
      dataIndex: "complaintsAgainst",
    },
    {
      title: "Description",
      dataIndex: "description",
      render:(record) =>{
        return(
          <Flex center={"true"} gap={'10px'}>
          <TbEye onClick={() => ViewDescriptionn(record)} />
          </Flex>
        )
      }
    },
    {
      title: 'Action',
      render: (record) => {
        return (
          <Flex center={"true"} gap={'10px'}>
            <FiEdit onClick={() => UpdateComplaints(record)} />
          </Flex>
        );
      },
    },
  ];

  let content;

  if (AllComplaintsStatus === 'loading') {
    content = <CommonLoading />
  } else if (AllComplaintsStatus === 'succeeded') {
    const rowKey = (dataSource) => dataSource.employeeId;
    content = <CustomStandardTable columns={columns} data={dataSource} rowKey={rowKey} />
  } else if (AllComplaintsStatus === 'failed') {
    content = <h2>{AllComplaintsErrors}</h2>
  }

  return (
    <div>
      <Flex>
        <CustomLableBack />
        <CustomPageTitle Heading={"Staff Complaints"} />
      </Flex>

      <CustomRow
        space={[24, 24]}
        style={{ background: "#dae1f3", paddingTop: "12px" }}
      >
        <Col span={24} md={10} style={{ display: "flex", gap: "10px", alignItems: 'baseline' }}>
          <CustomPageFormTitle Heading={"Complaints"} />
          <CustomInputSearch
            placeholder={"search ..."}
            value={searchTexts}
            onChange={(e) => handleSearchs(e.target.value)}
          />
        </Col>
        <Col span={24} md={14}>
          <CustomRow space={[24, 24]}>
            <Col span={24} md={16}></Col>
            <Col span={24} md={8} style={{ float: "right" }}>
              <Flex style={{ marginRight: "-30px", justifyContent: "end" }}>
                <Button.Primary
                  style={{ borderRadius: "6px" }}
                  icon={<FiPlus style={{ fontSize: "20px" }} />}
                  text={"Add"}
                  // onClick={() => navigate("/addemployee")}
                  onClick={AddEmployeeComplaints}
                />
              </Flex>
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
      {/* <CustomStandardTable
        columns={columns}
        data={data}
      /> */}
      {content}
      <CustomModal
        isVisible={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        width={widthsize}
        modalTitle={modalTitle}
        modalContent={modalContent}
      />
    </div>
  )
}
