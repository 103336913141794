import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";
import { APIURLS } from "../../utils/ApiUrls";

const initialState = {
    salary: [],
    payroll: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

// get  salary

export const getSalary = createAsyncThunk(
    "salaryy",
    async () => {
        try {
            const payrollMonth = 'pay';
            const response = await request.get(`${APIURLS.GETBASICSALARY}`, {
                params: { payrollMonth }
            })
            return [...response.data];
        } catch (error) {
            // throw error;
        }
    });

// get  Payroll

export const getPayroll = createAsyncThunk(
    "get/payroll",
    async () => {
        try {
            const payrollMonth = 'payrollDetails';
            const response = await request.get(`${APIURLS.GETPAYROLL}`, {
                params: { payrollMonth }
            })
            return [...response.data];
        } catch (error) {
            // throw error;
        }
    });

const payrollSlice = createSlice({
    name: "salarydetails",
    initialState,
    reducers: {
        addSalary: (state, action) => {
            state.salarydetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Salary

            .addCase(getSalary.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getSalary.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.salary = action.payload;
            })
            .addCase(getSalary.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })


            // Payroll

            .addCase(getPayroll.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getPayroll.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.payroll = action.payload;
            })
            .addCase(getPayroll.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })

    },
});

// Salary

export const selectAllSalary = (state) => state.salaries.salary;
export const getSalaryStatus = (state) => state.salaries.status;
export const getSalaryError = (state) => state.salaries.error;

// Payroll

export const selectAllPayroll = (state) => state.salaries.payroll;
export const getPayrollStatus = (state) => state.salaries.status;
export const getPayrollError = (state) => state.salaries.error;

export const { reducer } = payrollSlice;
export const { addSalary } = payrollSlice.actions;

export default payrollSlice.reducer;
