import React, { useEffect, useRef, useState } from "react";
import { AttendanceData } from "./DashboardData";
import { StyledChart } from "../style";
import * as echarts from 'echarts';
import { useDispatch, useSelector } from "react-redux";
import { getAttendance, selectAllAttendance } from "../DashboardSlice";

export const LineGraph = () => {

  const chartRef = useRef(null);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAttendance())
  }, [])

const AllAttendance = useSelector(selectAllAttendance)  

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const month = AllAttendance?.map((item) => {
      return item.month
    });
    const presentCount = AllAttendance?.map((item) => {
      return item.present_count
    });
    const absentCount = AllAttendance?.map((item) => {
      return item.absent_count
    });

    const options = {
      title: {
        text: 'Attendance'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: month || [0],
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'present',
          type: 'line',
          stack: 'Total',
          smooth: true,
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: presentCount || [0],
        },
        {
          name: 'Absent',
          type: 'line',
          stack: 'Total',
          smooth: true,
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: absentCount || [0],
        },
      ]
    };

    chart.setOption(options);

    // Cleanup the chart when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [AllAttendance]);

  return (
    <StyledChart ref={chartRef}></StyledChart>
  );
};
