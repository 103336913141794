import React, { useEffect, useState } from 'react'
import { BusinessCard, StyledBusinessProfile, StyledDetails } from '../style'
import { CustomRow } from '../../../components/CustomRow'
import { Col } from 'antd'
import { CustomCardView } from '../../../components/CustomCardView'
import { Details, EmpDetails } from '../../EmployeeDetails/Style'
import { GrEdit } from 'react-icons/gr'
import { getBusinessProfile, selectAllBusinessProfile } from '../BusinessSlice'
import { useDispatch, useSelector } from 'react-redux'
import { base } from '../../../utils/request'
import { CustomModal } from '../../../components/CustomModal'
import { BusinessProfile } from './BusinessProfile'
import { IoReturnUpBackOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'


export const ViewBusinessProfile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalContent, setModalContent] = useState(null)
    const [formReset, setFormReset] = useState(0);

    useEffect(() => {
        dispatch(getBusinessProfile())
    }, [])

    const profdetails = useSelector(selectAllBusinessProfile)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false);
    }

    const FormRest = () => {
        setFormReset(formReset + 1);
    };

    const FormExternalClose = () => {
        handleOk();
        FormRest()
    }

    const BusinessProfileView = () => {
        setFormReset(formReset + 1);
        setModalTitle('Business Profile');
        setModalContent(<BusinessProfile FormExternalClosee={FormExternalClose} formname={'AddBusinessProfile'} updatetrigger={formReset} />);
        showModal()
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        FormRest()
    }

    const TakeMeBack = () => {
        navigate(-1)
    }

    return (
        <CustomRow>
            <Col span={24} md={24}>
                <CustomCardView>
                    <IoReturnUpBackOutline onClick={TakeMeBack} style={{ fontSize: '20px' }} />
                    <CustomRow space={[12, 12]}>
                        <Col span={24} md={10}>
                            <EmpDetails>
                                <img
                                    src={`${base}${profdetails?.url}`}
                                    alt="img"
                                    width={100}
                                    height={100}
                                    style={{ borderRadius: "25%"}}
                                />
                                <div style={{ marginLeft: "20px" }}>
                                    <p
                                        style={{
                                            fontWeight: "900",
                                            fontSize: "25px",
                                            color: "#000",
                                        }}
                                    >
                                    </p>
                                    <br />
                                    <p></p>
                                    <br />
                                    <h1 style={{ color: "#000" }}>
                                        {profdetails?.companyName}
                                    </h1>

                                </div>

                            </EmpDetails>
                        </Col>
                        <Col span={24} md={14}>
                            <Details>
                                <CustomRow
                                    style={{ position: "relative" }}
                                    space={[12, 12]}
                                >
                                    <Col span={24} md={24} sm={24}>
                                        <div style={{ display: "flex", textAlign: "center", justifyContent: "space-between" }}>
                                            <p className="cardheading">Details :</p>
                                            <div className="icon-places">

                                                <GrEdit
                                                    className="icon"
                                                    onClick={() => {
                                                        BusinessProfileView();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={24} md={24} sm={24}>
                                        <CustomRow space={[12, 12]}>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">Phone :</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue">
                                                    {profdetails?.phoneNumber1}
                                                </p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">Alterative Number :</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue">
                                                    {profdetails?.phoneNumber2}
                                                </p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">Email:</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue">
                                                    {profdetails?.email}
                                                </p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">Location</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue">{profdetails?.location}</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">Address:</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue"> {profdetails?.address}</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">Country :</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue">{profdetails?.country}</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">State :</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue">{profdetails?.state}</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="para">Pincode :</p>
                                            </Col>
                                            <Col span={24} md={12} sm={12}>
                                                <p className="paraBlue">{profdetails?.pincode}</p>
                                            </Col>
                                        </CustomRow>
                                    </Col>

                                </CustomRow>
                            </Details>
                        </Col>
                    </CustomRow>
                </CustomCardView>
            </Col>

            <Col span={24} md={12} style={{ marginTop: '25px' }}>
                <CustomCardView>
                    <StyledDetails>
                        <CustomRow space={[12, 12]}>
                            <Col span={24} md={24} sm={24}>
                                <div style={{ display: "flex", textAlign: "center", justifyContent: "space-between" }}>
                                    <p className="cardheading">Bank Details :</p>
                                    <div className="icon-places">

                                        <GrEdit
                                            className="icon"
                                            onClick={() => {
                                                BusinessProfileView();
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col span={24} md={11} sm={11}>
                                <p className="para">Account Holder Name</p>
                            </Col>
                            <Col span={24} md={13} sm={13}>
                                <p className="paraBlue">{profdetails?.holderName}</p>
                            </Col>

                            <Col span={24} md={11} sm={11}>
                                <p className="para">Bank Name</p>
                            </Col>
                            <Col span={24} md={13} sm={13}>
                                <p className="paraBlue">{profdetails?.bankName}</p>
                            </Col>

                            <Col span={24} md={11} sm={11}>
                                <p className="para">Branch Name</p>
                            </Col>
                            <Col span={24} md={13} sm={13}>
                                <p className="paraBlue">{profdetails?.branchName}</p>
                            </Col>

                            <Col span={24} md={11} sm={11}>
                                <p className="para">Account Number</p>
                            </Col>
                            <Col span={24} md={13} sm={13}>
                                <p className="paraBlue">{profdetails?.accountNo}</p>
                            </Col>

                            <Col span={24} md={11} sm={11}>
                                <p className="para">GST No</p>
                            </Col>
                            <Col span={24} md={13} sm={13}>
                                <p className="paraBlue">{profdetails?.gstNo}</p>
                            </Col>

                            <Col span={24} md={11} sm={11}>
                                <p className="para">Tax No</p>
                            </Col>
                            <Col span={24} md={13} sm={13}>
                                <p className="paraBlue">{profdetails?.taxNo}</p>
                            </Col>

                            <Col span={24} md={11} sm={11}>
                                <p className="para">IFSC Code</p>
                            </Col>
                            <Col span={24} md={13} sm={13}>
                                <p className="paraBlue">{profdetails?.ifscCode}</p>
                            </Col>
                        </CustomRow>
                    </StyledDetails>
                </CustomCardView>
            </Col>
            <CustomModal isVisible={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} modalTitle={modalTitle} modalContent={modalContent} />
        </CustomRow>

    )
}
