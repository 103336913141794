import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'antd';
import dayjs from 'dayjs';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import request from '../../../../utils/request';
import { CustomStandardTable } from '../../../../components/Form/CustomStandardTable';
import { APIURLS } from '../../../../utils/ApiUrls';
import { toast } from 'react-toastify';
import Flex from '../../../../components/Flex';
import { CustomSelect } from '../../../../components/Form/CustomSelect';
import { getInitialEmployee, selectAllInitialEmployee } from '../../../EmployeeDetails/EmployeeSlice';


export const AttendanceReportPage = () => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [dataSource, setDataSource] = useState([])
    const [searchTexts, setSearchTexts] = useState([]);

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const handleOnChange = (id) => {
        GetPayrollDetails(id);

    };

    const GetPayrollDetails = (id) => {
        request.get(`${APIURLS.GETATTENDANCEREPORT}${id}`)
            .then(function (response) {
                setDataSource(response.data)
                toast.success("Details Fetched Successfully . . . ");

            })
            .catch(function (error) {
            })
    };

    const onFinish = (values) => {
        GetPayrollDetails(values)
    }

    const onFinishFailed = (errorInfo) => {
    };

    useEffect(() => {
        dispatch(getInitialEmployee())
    }, [])

    const AllEmployeeDetails = useSelector(selectAllInitialEmployee)

    const AllEmployeeOptions = AllEmployeeDetails?.map((emp) => (
        {
            label: emp?.userName,
            value: emp?.employeeId
        }
    ))

    const TableColumn = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Absent Date',
            dataIndex: 'absent_date',
        },
        {
            title: 'Day',
            dataIndex: 'day_name',
        },
        {
            title: 'Department',
            dataIndex: 'department_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return (
                    String(record.departmentName)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.departmentName).includes(value.toUpperCase())
                );
            },
        }
    ];

    return (
        <Fragment>
            <CustomPageTitle Heading={'Month Absentees Report'} /><br />
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={
                    {
                        from_date: dayjs(),
                        to_date: dayjs(),
                    }
                }
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Flex spaceBetween>
                    <CustomSelect label={'Staff Name'} name={'name'} options={AllEmployeeOptions} onChange={handleOnChange} />
                    <p style={{marginRight:'20px'}}>{dataSource[0]?.user_id}</p>
                </Flex>

            </Form>

            {/* <Flex spaceAround={"true"} gap={"20px"} style={{marginTop:'7px'}}>
                <CustomInputSearch 
                    placeholder={"Search by Department"}
                    value={searchTexts}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </Flex> */}

            <div style={{ marginTop: '15px' }}>

                <CustomStandardTable columns={TableColumn} data={dataSource} />
            </div>
        </Fragment >
    )
}

