import { Col, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { CustomRow } from "../../../components/CustomRow";
import { CustomSelect } from "../../../components/Form/CustomSelect";
import { CustomDatePicker } from "../../../components/Form/CustomDatePicker";
import { CustomInput } from "../../../components/Form/CustomInput";
import { CustomTextArea } from "../../../components/Form/CustomTextArea";
import Flex from "../../../components/Flex";
import Button from "../../../components/Form/CustomButton";
import dayjs from "dayjs";
import { CustomUpload } from "../../../components/Form/CustomUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  getInitialEmployee,
  selectAllInitialEmployee,
} from "../../EmployeeDetails/EmployeeSlice";
import { APIURLS } from "../../../utils/ApiUrls";
import request, { base } from "../../../utils/request";
import { getComplaints } from "../ComplaintsSlice";

export const ComplaintsForm = ({
  FormExternalClosee,
  complaintsrecord,
  updatetrigger,
  formReset,
}) => {
  const [form] = useForm();
  const [ImageInitialValue, setImageInitialValue] = useState([]);
  const [updateFormData, setUpdateFormData] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  useEffect(() => {
    form.resetFields();
  }, [form, updatetrigger]);

  useEffect(() => {
    if (complaintsrecord) {
      setComplaints();
    }
  }, [updateFormData, complaintsrecord, updatetrigger]);

  const setComplaints = () => {
    const complaintDate = new Date(complaintsrecord?.complaintsDate);
    const dateFormat = "YYYY/MM/DD";
    const complainttDate = dayjs(complaintDate).format(dateFormat);

    form.setFieldsValue(complaintsrecord);
    form.setFieldsValue({
      complaintsDate: dayjs(complainttDate, dateFormat),
    });
    form.setFieldsValue({ attachments: ImageInitialValue });
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  useEffect(() => {
    if (complaintsrecord?.attachments?.length > 0) {
      setImageInitialValue([
        {
          uid: "1",
          name: "example.jpg",
          status: "done",
          url: `${base}${complaintsrecord?.attachments}`,
        },
      ]);
    } else {
      setImageInitialValue([]);
    }

    setUpdateFormData(complaintsrecord);
  }, [complaintsrecord]);

  const handleDate = (date) => {
    setSelectedDate(date);
  };

  const onFinish = (values) => {

    if (complaintsrecord) {
      const formData = new FormData();
      formData.append("employeeId", values.employeeId);
      formData.append("complaintsTitle", values.complaintsTitle);
      formData.append(
        "complaintsDate",
        dayjs(values.complaintsDate).format("YYYY-MM-DD")
      );
      formData.append("complaintsAgainst", values.complaintsAgainst);
      formData.append("description", values.description);
      if (values?.attachments[0].originFileObj) {
        values.attachments.forEach((file) => {
          formData.append(`attachments`, file.originFileObj);
        });
      }
      UpdateComplaints(formData, complaintsrecord?.complaintsId);
    } else {
      const formData = new FormData();
      formData.append("employeeId", values.employeeId);
      formData.append("complaintsTitle", values.complaintsTitle);
      formData.append(
        "complaintsDate",
        dayjs(values.complaintsDate).format("YYYY-MM-DD")
      );
      formData.append("complaintsAgainst", values.complaintsAgainst);
      formData.append("description", values.description);

      if (values?.attachments && values.attachments.length > 0) {
        values.attachments.forEach((file) => {
          formData.append(`attachments`, file.originFileObj);
        });
      } else {
        console.error("");
      }
      AddComplaints(formData);
    }
  };

  const AddComplaints = (values) => {
    request
      .post(`${APIURLS.POSTCOMPLAINT}`, values)
      .then(function (response) {
        toast.success("Complaints Added Successfully");
        form.resetFields();
        dispatch(getComplaints());
        FormExternalClosee();
        form.resetFields();
      })
      .catch(function (error) {
      });
  };

  const UpdateComplaints = (values, id) => {
    request
      .put(`${APIURLS.PUTCOMPLAINT}${id}/`, values, config)
      .then(function (response) {
        toast.info("Complaints Updated Successfully");
        dispatch(getComplaints());
        FormExternalClosee();
      })
      .catch(function (error) {});
  };

  const onFinishFailed = (value) => {};

  const onReset = () => {
    form.resetFields();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitialEmployee());
  }, []);

  const AllEmployeeDetails = useSelector(selectAllInitialEmployee);

  const AllEmployeeOptions = AllEmployeeDetails?.map((emp) => ({
    label: emp.userName,
    value: emp.employeeId,
  }));

  return (
    <Form
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <CustomRow space={[12, 12]}>
        <Col span={24} md={12}>
          <CustomSelect
            label={"Staff Name"}
            placeholder={"Staff Name"}
            options={AllEmployeeOptions}
            name={"employeeId"}
            rules={[
              {
                required: true,
                message: "Please Enter Staff Name ! ! !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            name={"complaintsTitle"}
            label={"Complaints Title"}
            placeholder={"Enter Complaints Title"}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomDatePicker
            label={"Complaints Date"}
            name={"complaintsDate"}
            placeholder={"Complaints Date"}
            onChange={handleDate}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            name={"complaintsAgainst"}
            label={"Complaints Against"}
            placeholder={"Enter Complaints Against"}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomTextArea
            rows={2}
            label={"Description"}
            name={"description"}
            placeholder={"Enter Description"}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomUpload
            form={form}
            label={"Upload Attachment"}
            name={"attachments"}
            maxCount={1}
            listType="picture-card"
            initialValue={ImageInitialValue}
            rules={[
              {
                required: true,
                message: "Please Upload Attachments!",
              },
            ]}
          />
        </Col>
      </CustomRow>

      <Flex gap={"20px"} center={"true"} margin={"20px 0"}>
        {complaintsrecord ? (
          <>
            <Button.Primary text={"Update"} htmlType={"submit"} />
            <Button.Danger
              text={"Cancel"}
              onClick={() => FormExternalClosee()}
            />
          </>
        ) : (
          <>
            <Button.Success text={"Submit"} htmlType={"submit"} />
            <Button.Danger text={"Reset"} onClick={() => onReset()} />
          </>
        )}
      </Flex>
    </Form>
  );
};
