import { Col, Form } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomRow } from "../../../components/CustomRow";
import { CustomDatePicker } from "../../../components/Form/CustomDatePicker";
import { CustomInput } from "../../../components/Form/CustomInput";
import Button from "../../../components/Form/CustomButton";
import Flex from "../../../components/Flex";
import { useForm } from "antd/es/form/Form";
import request from "../../../utils/request";
import { APIURLS } from "../../../utils/ApiUrls";
import { toast } from "react-toastify";
import { getTraining } from "../TrainingSlice";

export const TrainingForm = ({
  FormExternalClose,
  FormExternalClosee,
  updatetrigger,
  trainingrecord,
}) => {
  const [form] = useForm();
  const [startedDate, setStartedDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [endedDate, setEndedDate] = useState(dayjs().format("YYYY-MM-DD"));

  useEffect(() => {
    form.resetFields();
  }, [form, updatetrigger]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (trainingrecord) {
      setTraining();
    }
  }, [trainingrecord, updatetrigger]);

  const setTraining = () => {
    const startDatee = new Date(trainingrecord?.startDate);
    const endDatee = new Date(trainingrecord?.endDate);
    const dateFormat = "YYYY/MM/DD";
    const starttDate = dayjs(startDatee).format(dateFormat);
    const enddDate = dayjs(endDatee).format(dateFormat);

    form.setFieldsValue(trainingrecord);
    form.setFieldsValue({ startDate: dayjs(starttDate, dateFormat) });
    form.setFieldsValue({ endDate: dayjs(enddDate, dateFormat) });
  };

  const UpdateTraining = (values) => {
    request
      .put(`${APIURLS.PUTTRAINING}${trainingrecord?.traineeId}`, values)
      .then(function (response) {
        toast.info("Training Details Updated Successfully");
        dispatch(getTraining());
        FormExternalClosee();
      })
      .catch((error) => {});
  };

  const handleStartedDate = (date) => {
    setStartedDate(date);
    // const dayOfWeek = dayjs(date).format("YYYY/MM/DD");
    // form.setFieldsValue({ startDate: dayOfWeek });
  };

  const handleEndedDate = (date) => {
    setEndedDate(date);
    // const dayOfWeek = dayjs(date).format("YYYY/MM/DD");
    // form.setFieldsValue({ endDate: dayOfWeek });
  };

  const AddTraining = (value) => {
    request
      .post(`${APIURLS.POSTTRAINING}`, value)
      .then(function (response) {
        dispatch(getTraining());
        FormExternalClose();
        form.resetFields();
        toast.success("Trainee Added Successfully");
      })
      .catch((error) => {});
  };

  const onFinish = (values) => {

    if (trainingrecord) {
      const NewValues = { ...values, startDate: startedDate,endDate: endedDate}
      UpdateTraining(NewValues);
    } else {
      AddTraining(values);
    }
  };

  const onFinishFailed = (value) => {};

  const onReset = () => {
    form.resetFields();
  };
  return (
    <Form
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div style={{ margin: "30px 0px" }}>
        <CustomRow space={[12, 12]}>
          <Col span={24} md={12}>
            <CustomInput
              label={"Trainee Name"}
              placeholder={"Enter Trainee Name"}
              name={"traineeName"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Trainee Name ! ! !",
                },
              ]}
            />
          </Col>

          <Col span={24} md={12}>
            <CustomInput
              label={"Email ID"}
              placeholder={"Enter Email ID"}
              name={"emailId"}
              type={"email"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Email ID !",
                },
              ]}
            />
          </Col>

          <Col span={24} md={12}>
            <CustomInput
              name={"mobileNumber"}
              label={"Mobile Number"}
              placeholder={"Enter Mobile Number"}
              maxLength={10}
              minLength={10}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Mobile Number !",
                },
              ]}
            />
          </Col>

          <Col span={24} md={12}>
            <CustomInput
              label={"College Name"}
              placeholder={"Enter College Name"}
              name={"collegeName"}
              rules={[
                {
                  required: true,
                  message: "Please Enter College Name ! ! !",
                },
              ]}
            />
          </Col>

          <Col span={24} md={12}>
            <CustomDatePicker
              label={"Start Date"}
              placeholder={"Enter Start Date"}
              onChange={handleStartedDate}
              name={"startDate"}
              rules={[
                {
                  required: true,
                  message: "Please Enter startDate! ! !",
                },
              ]}
            />
          </Col>

          <Col span={24} md={12}>
            <CustomDatePicker
              label={"endDate"}
              placeholder={"Enter endDate"}
              onChange={handleEndedDate}
              name={"endDate"}
              rules={[
                {
                  required: true,
                  message: "Please Enter endDate ! ! !",
                },
              ]}
            />
          </Col>
        </CustomRow>
      </div>
      <Flex gap={"20px"} center={"true"} margin={"20px 0"}>
        {trainingrecord ? (
          <>
            <Button.Primary text={"Update"} htmlType={"submit"} />
            <Button.Danger
              text={"Cancel"}
              onClick={() => FormExternalClosee()}
            />
          </>
        ) : (
          <>
            <Button.Success text={"Submit"} htmlType={"submit"} />
            <Button.Danger text={"Reset"} onClick={() => onReset()} />
          </>
        )}
      </Flex>
    </Form>
  );
};
