import GlobalStyle from './theme/GlobalStyle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment, useEffect, useLayoutEffect } from 'react';
import Routers from './routes';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentRoleID, selectCurrentToken, selectCurrentUser } from './modules/Auth/authSlice';



function App() {

  const location = useLocation()

  const token = useSelector(selectCurrentToken);
  const userr = useSelector(selectCurrentUser)

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Fragment>
      <GlobalStyle />
      <Routers token={token}/>
      <ToastContainer />
    </Fragment>
  );
}

export default App;