import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomModal } from '../../../components/CustomModal'
import { FiEdit, FiPlus } from 'react-icons/fi'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import { CustomRow } from '../../../components/CustomRow'
import { Col } from 'antd'
import { CustomPageFormTitle, CustomPageTitle } from '../../../components/CustomPageTitle'
import CustomInputSearch from '../../../components/Form/CustomInputSearch'
import { HolidayForm } from './HolidayForm'
import { getHoliday, getHolidayError, getHolidayStatus, selectAllHoliday } from '../HolidaySlice'
import { CustomStandardTable } from '../../../components/Form/CustomStandardTable'
import { CommonLoading } from '../../../components/CommonLoading'
import { IoReturnUpBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom'
import { CustomLableBack } from '../../../components/CustomLableBack'

const ViewHoliday = () => {

    const [dataSource, setDataSource] = useState([])
    const [searchTexts, setSearchTexts] = useState([]);
    const [formReset, setFormReset] = useState(0);
    const [trigger, setTrigger] = useState(0)
    const dispatch = useDispatch()

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormRest()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        FormRest()
    };

    const FormRest = () => {
        setFormReset(formReset + 1);
    };

    const FormExternalClose = () => {
        handleOk();

    };

    useEffect(() => {
        dispatch(getHoliday())
    }, [])

    const AllHolidayDetails = useSelector(selectAllHoliday)
    const AllHolidayStatus = useSelector(getHolidayStatus)
    const AllHolidayError = useSelector(getHolidayError)

    const UpdateHoliday = (record) => {
        setTrigger(trigger + 1)
        setModalTitle('Update Holiday');
        setModalContent(<HolidayForm FormExternalClosee={FormExternalClose} formname={'editholiday'} formReset={formReset} holidayrecord={record} updatetrigger={trigger} />);
        showModal()
    }

    useEffect(() => {
        setDataSource(AllHolidayDetails)
    }, [AllHolidayDetails])

    const TableColumn = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Title",
            dataIndex: "title",
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return (
                    String(record.title)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.title).includes(value.toUpperCase())
                );
            },
        },
        {
            title: "Day",
            dataIndex: "day",
        },

        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <FiEdit onClick={() => UpdateHoliday(record)} />
                    </Flex>
                );
            },
        },
    ]

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const AddHoliday = () => {
        setModalTitle("Add Holidays");
        setTrigger(trigger + 1)
        setModalContent(
            <HolidayForm
                formname={"AddHolidayForm"}
                FormExternalClose={FormExternalClose}
                updatetrigger={trigger}
            />
        );
        showModal();
    };

    let content;

    if (AllHolidayStatus === 'loading') {
        content = <CommonLoading />
    } else if (AllHolidayStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.holidaysId;
        content = <CustomStandardTable columns={TableColumn} data={dataSource} rowKey={rowKey} />
    } else if (AllHolidayStatus === 'failed') {
        content = <h2>
            {AllHolidayError}
        </h2>
    }

    return (
        <div>
            <Flex>
                <CustomLableBack />
                <CustomPageTitle Heading={"Holiday Details"} />
            </Flex>
            <CustomRow
                space={[24, 24]}
                style={{ background: "#dae1f3", paddingTop: "12px" }}
            >
                <Col span={24} md={10} style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
                    <CustomPageFormTitle Heading={"Holiday"} />
                    <CustomInputSearch
                        placeholder={"search ..."}
                        value={searchTexts}
                        onChange={(e) => handleSearchs(e.target.value)}
                    />
                </Col>
                <Col span={24} md={14}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}></Col>
                        <Col span={24} md={8} style={{ float: "right" }}>
                            <Flex style={{ marginRight: "-30px", justifyContent: "end" }}>
                                <Button.Primary
                                    style={{ borderRadius: "6px" }}
                                    icon={<FiPlus style={{ fontSize: "20px" }} />}
                                    text={"Add"}
                                    onClick={AddHoliday}
                                />
                            </Flex>
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
            {content}
            <CustomModal
                isVisible={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                width={800}
                modalTitle={modalTitle}
                modalContent={modalContent}
            />
        </div>
    )
}

export default ViewHoliday