import { Divider, Menu } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineDashboard, AiOutlineTags, AiFillMoneyCollect, AiOutlinePayCircle, AiOutlineFolderView } from "react-icons/ai";
import { useEffect, useState } from "react";
import { BsFillClipboard2DataFill, BsFillClipboard2PulseFill, BsFillFileEarmarkPersonFill, BsFillFileEarmarkRuledFill, BsFillPersonVcardFill, BsFillPinMapFill, BsGraphUpArrow } from "react-icons/bs";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../components/Flex";
import { BiSolidExit, BiUserPlus } from 'react-icons/bi'
import { MdOnDeviceTraining, MdOutlineAutoGraph, MdOutlineModelTraining, MdOutlineRateReview, MdRateReview } from 'react-icons/md'
import { GiPayMoney, GiTimeSynchronization, GiTimeTrap } from 'react-icons/gi'
import Button from "../../components/Form/CustomButton";
import { CustomModal } from "../../components/CustomModal";
import { logOut, selectCurrentProfile, selectCurrentRole, selectCurrentUser, selectCurrentUserId } from "../../modules/Auth/authSlice";
import { getAdminAccount, selectAllAdminAccount } from "../../modules/AdminAccount/AdminAccountSlice";
import request, { base } from "../../utils/request";
import { APIURLS } from "../../utils/ApiUrls";
import { TbDoorExit } from "react-icons/tb";
import { FaFileSignature } from "react-icons/fa6";


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const Profile = styled.div`
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin: 20px 0 10px 0px;
gap: 10px;
padding: 5px 20px;


`

const ImageProfile = styled.div`
text-align: center;

    & img {
        width: 120px;
        height: 120px;
        margin:auto;
        border-radius: 20%;
    }
`
const MenuText = styled.div`
   font-size: 16px;
   color: #545454;
   padding-left: 7px;
`

export const SideMenu = ({ collapsed }) => {


    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [openKeys, setOpenKeys] = useState([]);
    const [activeTab, setActiveTab] = useState("")

    const route = useLocation();

    const role = useSelector(selectCurrentRole)
    const EnteredName = useSelector(selectCurrentUser)
    const EnteringProfilePic = useSelector(selectCurrentProfile)

    useEffect(() => {
        const pathname = route.pathname;
        const parts = pathname.split('/');
        const lastPart = parts[1];

        setActiveTab(lastPart)

        const storedOpenKeys = JSON.parse(localStorage.getItem('openKeys'));
        if (storedOpenKeys) {
            setOpenKeys(storedOpenKeys);
        }
    }, [route])

    const dispatch = useDispatch()

    const EnteringProfile = useSelector(selectCurrentProfile)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const rootSubinmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8'];

    const onOpenChange = (keys) => {
        // Store the open keys in local storage
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubinmenuKeys.indexOf(latestOpenKey) === -1) {
            localStorage.setItem('openKeys', JSON.stringify(keys));
            setOpenKeys(keys);
        } else {
            localStorage.setItem('openKeys', JSON.stringify(latestOpenKey ? [latestOpenKey] : []));
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const items = [
        getItem('Dashboard', '', <AiOutlineDashboard />),
        (role === 'SuperAdmin' || role === 'Admin') && getItem('Business Profile', 'business_profile', <BsFillFileEarmarkPersonFill />),

        getItem(<MenuText>{collapsed ? null : 'HRM'}</MenuText>, 'menu', null,
            [
                (role === 'SuperAdmin' || role === 'Admin') && getItem('User', 'user', <BiUserPlus />),

                getItem('Staffs', 'sub1', <AiOutlineTags />, [
                    getItem('Profile', 'viewprofile', <BsFillPersonVcardFill />),
                    getItem('Other Tables', 'extra_table', <BsFillPinMapFill />),
                    getItem('Attendance', 'attendance', <GiTimeSynchronization />),
                    getItem('Edit Attendance', 'editattendance', <GiTimeTrap />),
                    // getItem('Requirement', 'requirement', <AiOutlineFundView />),
                ]),

                getItem('Payroll', 'sub2', <AiFillMoneyCollect />, [
                    getItem('Staff Basic Salary', 'basicsalary', <AiOutlinePayCircle />),
                    getItem('View Basic Salary', 'view_basicsalary', <AiOutlineFolderView />),
                    getItem('Payroll', 'payroll', <GiPayMoney />),
                    getItem('View Payroll', 'view_payroll', <AiOutlineFolderView />),
                ]),
                // getItem('Leave', 'sub3', <BsFillClipboard2DataFill />, [
                //     getItem('Staff Leave', 'leave', <BsFillFileEarmarkRuledFill />),
                // ]),
                getItem('Holiday', 'sub3', <BsFillClipboard2DataFill />, [
                    getItem('Holidays', 'holiday', <BsFillFileEarmarkRuledFill />),
                ]),
                getItem('Promotions', 'sub4', <BsGraphUpArrow />, [
                    getItem('Promotions', 'promotions', <MdOutlineAutoGraph />),
                ]),
                getItem('Complaint', 'sub5', <MdOutlineRateReview />, [
                    getItem('Complaints', 'complaint', <MdRateReview />),
                ]),
                getItem('Exit Type', 'sub6', <TbDoorExit />, [
                    getItem('Exit', 'exit', <BiSolidExit />),
                ]),
                getItem('Training', 'sub7', <MdOutlineModelTraining />, [
                    getItem('Training', 'training', <MdOnDeviceTraining />),
                ]),

                getItem('Reports', 'sub8', <BsFillClipboard2PulseFill />, [
                    getItem('All Reports', 'all_reports', <FaFileSignature />)
                ]),
            ], 'group'),
    ]

    const navigate = useNavigate();

    const onClick = ({ key }) => {

        if (key === null) {
        }
        else {
            navigate(`${key}/`)
        }
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };


    const SearchDatas = styled.div`
    display: flex;
    align-items: center;
    gap: 35px;
    margin-left: 30px;
    color: #038fe0;
    `

    const Signout = () => {
        dispatch(logOut());
    }


    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex gap={'20px'} W_100 center verticallyCenter>
                <Button.Success text={'Logout'} onClick={Signout} />
                <Button.Danger text={'Cancel'} onClick={handleOk} />
            </Flex>
        </div>
    )

    const handleClick = () => {
        navigate('/addprofile');
    };


    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("Log Out");
        showModal();
    }

    return (
        <>
            <Profile>
                <div>
                    <ImageProfile>
                        <img src={`${base}${EnteringProfilePic}`} alt="Profile" />
                    </ImageProfile>
                    {!collapsed && (
                        <>
                            <p style={{ fontSize: "16px", color: "#545454" }}>{EnteredName}</p>
                            <p style={{ fontSize: "11px", color: "#545454" }}>{role}</p>
                        </>
                    )}
                </div>
            </Profile>
            <div style={{ margin: "auto", width: "82%" }}>
                <Divider style={{ background: "rgb(232 232 232 / 11%)" }} />
            </div>
            <div
                style={{
                    minHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                    position: "relative",
                }}
            >
                <Menu
                    style={{ color: "#545454", position: "absolute", width: "100%" }}
                    onClick={onClick}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    selectedKeys={[activeTab]}
                    // defaultSelectedKeys={[activeTab]}
                    mode="inline"
                    items={items}
                />
            </div>
            <CustomModal
                isVisible={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                width={400}
                modalTitle={modalTitle}
                modalContent={modalContent}
            />
        </>
    )
}

