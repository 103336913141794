import { combineReducers } from "redux"
import authReducer from '../modules/Auth/authSlice'
import employeeReducer from '../modules/EmployeeDetails/EmployeeSlice'
import payrollReducer from '../modules/Payroll/PayrollSlice' 
import HolidayReducer from '../modules/Holiday/HolidaySlice'
import promotionReducer from '../modules/Promotions/PromotionSlice'
import complaintsReducer from '../modules/Complaints/ComplaintsSlice'
import resignationReducer from '../modules/ExitType/Resignation/Partials/ResignationSlice'
import employeeTrueReducer from '../modules/ExitType/Exit/Partials/ExitSlice'
import businessprofileSlice from '../modules/BusinessProfile/BusinessSlice'
import employeeCountReducer from '../modules/Dashboard/DashboardSlice'
import notificationReducers from '../modules/Notifications/Partials/NotificationSlice'
import trainingReducers from '../modules/Training/TrainingSlice'
import reportsReducers from '../modules/Reports/ReportsSlice'
import adminAccountReducers from '../modules/AdminAccount/AdminAccountSlice'
import userDetailsReducers from '../modules/AddUsers/UserSlice'



const rootReducer = combineReducers({
    auth:authReducer,
    employee: employeeReducer,
    salaries:payrollReducer,
    holiday:HolidayReducer,
    promotions:promotionReducer,
    complaint:complaintsReducer,
    resignations:resignationReducer,
    employeesTrue:employeeTrueReducer,
    businessProfile:businessprofileSlice,
    employeeCountt:employeeCountReducer,
    notifications:notificationReducers,
    trainingg:trainingReducers,
    reports:reportsReducers,
    adminaccget:adminAccountReducers,
    userdetails:userDetailsReducers
})

export default rootReducer;