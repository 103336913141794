import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    token: null,
    authUser: null,
    roleType:null,
    roleId:null,
    userId:null,
    profile: null,
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { roleId,roleName,userName,token,id,profile} = action.payload
            
            state.authUser = userName
            state.roleType = roleName
            state.token = token
            state.roleId = roleId
            state.userId =id
            state.profile = profile
        },
        logOut: (state, action) => {
            state.authUser = null
            state.roleType = null
            state.token = null
            state.roleId = null
            state.userId = null
            state.profile = null
        }
    }
})
export const { setCredentials, logOut } = authSlice.actions
export const selectCurrentUser = (state) => state.auth.authUser
export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentRole = (state) => state.auth.roleType
export const selectCurrentRoleID = (state) => state.auth.roleId
export const selectCurrentUserId = (state) =>state.auth.userId
export const selectCurrentProfile = (state) =>state.auth.profile

export default authSlice.reducer