import Employee from '../../../Images/svg/Employee.svg'
import Present from '../../../Images/svg/Verified user.svg'
import Absent from '../../../Images/svg/Absent.svg'
import Trainee from '../../../Images/svg/apprenticeship.svg'
import Mail from '../../../Images/svg/Mail.svg'

export const SVGICONS = {
  EMPLOYEETOTAL:Employee,
  PRESENTSVG:Present,
  ABSENTSVG:Absent,
  TRAINEESVG:Trainee,
  MAILSVG:Mail,
}

  export const AttendanceData = [
    {
      "month": "January",
      "present": 4000,
      "absent": 2400,
    },
    {
      "month": "February",
      "present": 3000,
      "absent": 1398,
    },
    {
      "month": "March",
      "present": 2000,
      "absent": 9800,
    },
    {
      "month": "April",
      "present": 2780,
      "absent": 3908,
    },
    {
      "month": "May",
      "present": 1890,
      "absent": 4800,
    },
  ]