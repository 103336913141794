import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APIURLS } from "../../../../utils/ApiUrls";
import request from "../../../../utils/request";

const initialState = {
    employeestrue: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

// get  employeestrue

export const getEmployeesTrue = createAsyncThunk(
    "employeestrue",
    async () => {
        try {
            const trueParam = 'true';
            const response = await request.get(`${APIURLS.GETINITIALEMPLOYEETRUE}`, {
                params: { trueParam }
            })
            return [...response.data];
        } catch (error) {
            // throw error;
        }
    });


const employeeTrueSlice = createSlice({
    name: "emptrue",
    initialState,
    reducers: {
        addEmpTrue: (state, action) => {
            state.emptrue = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

      // employeestrue

            .addCase(getEmployeesTrue.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getEmployeesTrue.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.employeestrue = action.payload;
            })
            .addCase(getEmployeesTrue.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })

    },
});

// employeestrue

export const selectAllEmployeeTrue = (state) => state.employeesTrue.employeestrue;
export const getEmployeeTrueStatus = (state) => state.employeesTrue.status;
export const getEmployeeTrueError = (state) => state.employeesTrue.error;


export const { reducer } = employeeTrueSlice;
export const { addEmpTrue } = employeeTrueSlice.actions;

export default employeeTrueSlice.reducer;
