import React from 'react'
import { AddCandidateForm } from './Partials/AddCandidate'
import { CustomCardView } from '../../../components/CustomCardView'
import { InterviewSchedule } from './Partials/InterviewSchedule'
import { InterviewProcess } from './Partials/InterviewProcess'
import { TaskAssigned } from './Partials/TaskAssigned'
import { GroupDiscussion } from './Partials/GroupDiscussion'
import { Appointment } from './Partials/Appointment'
import { Offer } from './Partials/Offer'
import { Training } from './Partials/Training'
import { HRinterview } from './Partials/HRinterview'

export const Requirement = () => {
  return (
    <div>Requirement
      <CustomCardView>
        <AddCandidateForm />
        {/* <InterviewSchedule/> */}
        {/* <InterviewProcess /> */}
        {/* <TaskAssigned /> */}
        {/* <HRinterview /> */}
        {/* <GroupDiscussion /> */}
        {/* <Appointment /> */}
        {/* <Offer /> */}
        {/* <Training /> */}
      </CustomCardView>
    </div>
  )
}
