import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";
import { APIURLS } from "../../utils/ApiUrls";


const initialState = {
  payrollreport: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// get Holiday

export const getPayrollReports = createAsyncThunk(
  "payrollreports/get",
  async () => {
    try {
      const department  = 'CurrentMonth';
      const response = await request.get(`${APIURLS.GETPAYROLLREPORTS}`, {
        params: { department }
      })
      return [...response.data];
    } catch (error) {
      // throw error;
    }
  });

const reportsSlice = createSlice({
  name: "allreports",
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder

      // Payroll Reports

      .addCase(getPayrollReports.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPayrollReports.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payrollreport = action.payload;
      })
      .addCase(getPayrollReports.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

// Payroll Reports

export const selectPayrollReports = (state) => state.reports.payrollreport;
export const getPayrollReportsStatus = (state) => state.reports.status;
export const getPayrollReportsError = (state) => state.reports.error;

export const { reducer } = reportsSlice;
export const { addDepartment } = reportsSlice.actions;

export default reportsSlice.reducer;
