import React from 'react'
import ViewDepartments from './Partials/ViewDepartments'

export const Department = () => {
  return (
    <div>
        <ViewDepartments/>
    </div>
  )
}
